import { axiosApiInstance, USER_ENDPOINT } from "endpoint/index";

const getNonce = async (address) => {
  const response = await axiosApiInstance.get(
    `${USER_ENDPOINT}/get-nonce/${address}`
  );
  return response.data.nonce;
};

export default getNonce;
