import styled, { css } from "styled-components";
import { Card } from "@pancakeswap/uikit";

export const StyledHotCollectionCard = styled(Card)`
  border-radius: 24px;
  margin: 0px;
  transition: opacity 200ms;

  @media (min-width: 768px) {
    margin: 0px 20px;
  }

  & > div {
    border-radius: 24px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    ${({ disabled }) =>
      disabled
        ? ""
        : css`
            opacity: 0.9;
            &:hover {
              cursor: pointer;
              opacity: 1;
              outline: 1px solid;
              outline-color: blueviolet;
            }
          `}
  }
`;

export const StyledImage = styled.img`
  border-radius: 4px;
  height: auto;
`;
