import { useMarketContract } from "hooks/useContract";
import useToast from "hooks/useToast";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { cancelListingSuccess } from "state";
import { cancelListing as cancelListingFn } from "utils/calls/index";

const useCancelListing = ({ nftId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const marketPlace = useMarketContract();
  const dispatch = useDispatch();
  const { toastSuccess, toastError } = useToast();

  const cancelListing = useCallback(async () => {
    try {
      setIsLoading(true);
      await cancelListingFn(marketPlace, nftId);
      setIsLoading(false);
      dispatch(cancelListingSuccess({ nftId }));
      toastSuccess("Success", `Cancel listing ${nftId}`);
    } catch (error) {
      setIsLoading(false);
      toastError("Failed", error.message ?? "Something wrong");
    }
  }, [dispatch, marketPlace, nftId, toastError, toastSuccess]);

  return { onCancelListing: cancelListing, isLoading };
};

export default useCancelListing;
