import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRef } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { ChevronLeft } from "@styled-icons/entypo/ChevronLeft";
import { ChevronRight } from "@styled-icons/entypo/ChevronRight";
import styled from "styled-components";

const SwiperWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  .navigation-btn {
    width: 30px;
    height: 30px;
    top: calc(50% - 20px);
    position: absolute;
    z-index: 9999999;
    cursor: pointer;
  }

  .left {
    left: -10px;
  }

  .right {
    right: -10px;
  }
`;

const SwiperCustom = ({ data, Item, slidesPerView, spaceBetween }) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <SwiperWrapper>
      <Swiper
        modules={[Navigation]}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
      >
        {data.map((data, index) => (
          <SwiperSlide key={index}>{Item(data)}</SwiperSlide>
        ))}
        <div className="navigation-btn left" ref={navigationPrevRef}>
          <ChevronLeft color="#fff" />
        </div>
        <div className="navigation-btn right" ref={navigationNextRef}>
          <ChevronRight color="#fff" />
        </div>
      </Swiper>
    </SwiperWrapper>
  );
};

export default SwiperCustom;
