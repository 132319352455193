import useActiveWeb3React from "hooks/useActiveWeb3React";
import React, { useEffect } from "react";
import { Button } from "common";
import useAuth from "hooks/useAuth";
import { ConnectorNames } from "@pancakeswap/uikit";
import { useWalletAddress } from "state/user/hooks";
import useToast from "hooks/useToast";

export default function ConnectWalletButtonWrapper({ children }) {
  const { active, account } = useActiveWeb3React();
  const { activeMetamask, signSignature } = useAuth();
  // wallet address is set for current user in database
  const walletAddress = useWalletAddress();
  const { toastError, toastInfo } = useToast();

  const switchAccountAlert = () => {
    toastInfo(
      "Action",
      `Please switch ${walletAddress.slice(0, 5)}...${walletAddress.slice(
        walletAddress?.length - 5,
        walletAddress?.length - 1
      )} on your metamask`
    );
  };

  const isCorrectAddress =
    account &&
    walletAddress &&
    account?.toLocaleLowerCase() === walletAddress?.toLocaleLowerCase();

  useEffect(() => {
    if (
      account &&
      walletAddress &&
      account?.toLocaleLowerCase() !== walletAddress?.toLocaleLowerCase()
    ) {
      toastError(
        "Wrong account",
        "Account is set for this session isn't current address on metamask"
      );
    }
  }, [account, toastError, walletAddress]);

  const renderConnect = () => {
    if (!walletAddress) {
      if (active)
        return (
          <>
            <Button scale="xs" onClick={() => signSignature(false)}>
              Sign
            </Button>
          </>
        );

      return (
        <Button
          scale="xs"
          onClick={() => activeMetamask(ConnectorNames.Injected)}
        >
          Connect
        </Button>
      );
    }

    if (!active) {
      return (
        <>
          <Button
            scale="xs"
            onClick={() => activeMetamask(ConnectorNames.Injected)}
          >
            Connect
          </Button>
        </>
      );
    }

    if (!isCorrectAddress) {
      return (
        <>
          <Button onClick={switchAccountAlert} scale="xs">
            Switch account
          </Button>
        </>
      );
    }

    return <>{children}</>;
  };

  return <div>{renderConnect()}</div>;
}
