import { getFullDisplayBalance } from "utils/formatBalance";
import useTokenBalance from "./useTokenBalance";

const useTokenBalanceDisplay = (tokenAddress) => {
  const { balance, fetchStatus } = useTokenBalance(tokenAddress);
  const balanceDisplay = getFullDisplayBalance(balance, 18, 3);

  const balanceFormatted = balanceDisplay
    ? Number(balanceDisplay)
        .toFixed(3)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    : 0;

  return { balanceDisplay: balanceFormatted, fetchStatus };
};

export default useTokenBalanceDisplay;
