import { useCallback } from "react";
import { listNFT as listingNFTUtil } from "utils/calls/index";
import { useMarketContract } from "hooks/useContract";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { listingNFT as listingNFTAction } from "state/user/index";
import { useDispatch } from "react-redux";
import useToast from "hooks/useToast";

const useListNFT = (birdData) => {
  const marketPlace = useMarketContract();
  const dispatch = useDispatch();
  const { account } = useActiveWeb3React();
  const { library } = useActiveWeb3React();
  const { nftId, name } = birdData;
  const { toastSuccess, toastError } = useToast();

  const listNFT = useCallback(
    async (price) => {
      try {
        dispatch(listingNFTAction.pending({ nftId }));
        await listingNFTUtil(marketPlace, nftId, price, library);
        dispatch(listingNFTAction.fulfilled({ nftId, price, owner: account }));
        toastSuccess("Success", `Listing ${name}`);
      } catch (error) {
        dispatch(listingNFTAction.rejected({ nftId }));
        toastError("Failed", "Something wrong");
      }
    },
    [
      dispatch,
      nftId,
      marketPlace,
      library,
      account,
      toastSuccess,
      name,
      toastError,
    ]
  );

  return { onListingNFT: listNFT };
};

export default useListNFT;
