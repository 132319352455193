import getGasPrice from "../getGasPrice";
import { DEFAULT_GAS_LIMIT, DEFAULT_TOKEN_DECIMAL } from "../../config/index";
import BigNumber from "bignumber.js";
import { parseEther } from "@ethersproject/units";
import postSignatureListing from "state/user/postSignatureListing";
import getNonce from "state/user/getNonce";
import getSignatureBuy from "state/user/getSignatureBuy";
import getVRS from "utils/getVRS";
import contracts from "config/constants/contracts";

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
};

export const buyNFT = async (
  marketPlaceContract,
  buyer,
  seller,
  tokenId,
  deadline
) => {
  const gasPrice = getGasPrice();

  const nonce = await getNonce(seller);

  const signature = await getSignatureBuy(tokenId);

  const { v, r, s } = getVRS(signature);

  const txn = await marketPlaceContract.buyNFT(
    buyer,
    seller,
    tokenId,
    deadline,
    nonce,
    v,
    r,
    s,
    { ...options, gasPrice }
  );
  const txnReceipt = await txn.wait();
  if (!txnReceipt.status) throw new Error();
};

export const listNFT = async (marketPlaceContract, tokenId, price, library) => {
  const value = parseEther(price);
  const gasPrice = getGasPrice();
  const signer = await library.getSigner();
  const account = await signer.getAddress();

  const signature = await signer._signTypedData(
    {
      name: "permission",
      version: "1",
      chainId: process.env.REACT_APP_CHAIN_ID,
      verifyingContract: contracts.birdNFT[process.env.REACT_APP_CHAIN_ID],
    },
    {
      Permit: [
        { name: "owner", type: "address" },
        { name: "spender", type: "address" },
        { name: "tokenId", type: "uint256" },
        { name: "deadline", type: "uint256" },
      ],
    },
    {
      owner: account,
      spender: contracts.birdNFT[process.env.REACT_APP_CHAIN_ID],
      tokenId: tokenId,
      deadline: 0,
    }
  );

  await postSignatureListing(tokenId, signature);

  if (signature) {
    const txn = await marketPlaceContract.listingNFT(tokenId, value, {
      ...options,
      gasPrice,
    });

    const txnReceipt = await txn.wait();

    if (!txnReceipt.status) throw new Error("Listing failed");
  }
};

export const cancelListing = async (marketPlaceContract, tokenId) => {
  const gasPrice = getGasPrice();

  const txn = await marketPlaceContract.cancelListing(tokenId, {
    ...options,
    gasPrice,
  });

  const txnReceipt = await txn.wait();

  if (!txnReceipt.status) throw new Error("Cancel failed");
};
