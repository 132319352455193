import addresses from "../config/constants/contracts";

export const getAddress = (address) => {
  const chainId = process.env.REACT_APP_CHAIN_ID;
  return address?.[chainId] ? address[chainId] : address?.["56"];
};

export const getMarketPlaceAddress = () => {
  return getAddress(addresses.marketPlace);
};
