import { BigNumber } from "@ethersproject/bignumber";
import { createSelector } from "@reduxjs/toolkit";
import { BirdType } from "config/constants";

const selectMyNFT = (state) => {
  return state.user.userBirds.data;
};
const selectPackage = (state) => state.package.list.data;
const selectCurrentReward = (state) => state.user.currentReward.reward;

const combineMyNFTMapWithPackages = (myNFTs, packages) =>
  myNFTs.map((nft) => {
    const packagesOf = packages.filter(
      (element) => element?.birdId === nft?.birdId
    );
    return {
      ...nft,
      packagesOf,
    };
  });

const birdsWithName = (birds) => {
  if (!birds || birds.length <= 0) {
    return [];
  }
  let result = birds.map((el) => {
    let name;
    if (el?.birdId === 1) {
      name = BirdType.FREE;
    } else if (el?.birdId === 2) {
      name = BirdType.STANDARD;
    } else if (el?.birdId === 3) {
      name = BirdType.LUXURY;
    } else if (el?.birdId === 4) {
      name = BirdType.PREMIUM;
    }

    return {
      ...el,
      name: `${name?.toLocaleUpperCase()} #68${el.nftId}`,
    };
  });

  return result;
};

const marketBirdListed = (data) => {
  const birdsWithoutBirdFree = data.filter((el) => el.type !== "free");

  // ListedBird is bird with price > 0
  return birdsWithName(
    birdsWithoutBirdFree.filter((el) => BigNumber.from(el.price).gt(0))
  );
};

export const userNFT = createSelector(
  [selectMyNFT, selectPackage],
  (myNFTs, packages) =>
    combineMyNFTMapWithPackages(birdsWithName(myNFTs), packages)
);

export const currentListingNFT = createSelector(
  (state) => state.user,
  (user) => user.listing.data
);

export const currentOrderNFT = createSelector(
  (state) => state.user,
  (user) => user.order.data
);

export const marketBirds = createSelector(
  (state) => state.user.market.data,
  marketBirdListed
);

export const currentRewards = createSelector(
  [selectCurrentReward],
  (data) => data
);

export const isLoadingMyNFT = createSelector(
  (state) => state.user.userBirds.isLoading,
  (data) => data
);

export const isLoadingMarketNFT = createSelector(
  (state) => state.user.market.isLoading,
  (data) => data
);

export const walletAddress = createSelector(
  (state) => state.user.walletAddress,
  (data) => data
);
