import { Box, Text } from "@pancakeswap/uikit";
import React from "react";
import styled from "styled-components";

const TextStyled = styled(Text)`
  @media screen and (max-width: 450px) {
    display: none;
  }
`;

export default function Footer() {
  return (
    <Box position={"absolute"} bottom={"20px"}>
      <TextStyled
        mx={"auto"}
        fontSize="10px"
        textAlign={"center"}
        width={"70%"}
        color="white"
        className="noselect"
      >
        <div className="copy-right">@Copyright Savvycom</div>
        Savvybird is a demonstration (i.e., solely for non-commercial purposes
        to learn more about our blockchain technologies and services) of our
        being developed GameFi ecosystem. <br />
        <span style={{ color: "#372F47" }}>
          Report problem to savvybird@savvycom.ai
        </span>
      </TextStyled>
    </Box>
  );
}
