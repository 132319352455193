import { Modal } from "@pancakeswap/uikit";
import { useCallback, useState } from "react";
import { ModalInput } from "common/components";
import { Button } from "common/components";

const ListingModal = ({ title, onDismiss, onConfirm }) => {
  const [val, setVal] = useState("");

  const handleChange = useCallback(
    (e) => {
      if (e.currentTarget.validity.valid) {
        setVal(e.currentTarget.value.replace(/,/g, "."));
      }
    },
    [setVal]
  );

  return (
    <Modal
      headerBackground="gradients.cardHeader"
      title={"Listing NFT"}
      onDismiss={onDismiss}
    >
      <ModalInput value={val} onChange={handleChange} inputTitle={"Price"} />
      <Button
        onClick={async () => {
          onDismiss?.();
          await onConfirm(val);
        }}
      >
        Confirm
      </Button>
      <Button onClick={onDismiss}>Cancel</Button>
    </Modal>
  );
};

export default ListingModal;
