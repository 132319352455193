import useActiveWeb3React from "./useActiveWeb3React";
import { useMemo } from "react";
import { getContract, getProviderOrSigner } from "../utils";
import { getMarketPlaceContract } from "../utils/contractHelpers";
import ERC20_ABI from "config/abi/erc20.json";

// returns null on errors
function useContract(address, ABI = true, withSignerIfPossible = true) {
  const { library, account } = useActiveWeb3React();
  const signer = useMemo(
    () => (withSignerIfPossible ? getProviderOrSigner(library, account) : null),
    [withSignerIfPossible, library, account]
  );

  const canReturnContract = useMemo(
    () => address && ABI && (withSignerIfPossible ? library : true),
    [address, ABI, library, withSignerIfPossible]
  );

  return useMemo(() => {
    if (!canReturnContract) return null;
    try {
      return getContract(address, ABI, signer);
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [address, ABI, signer, canReturnContract]);
}

export const useMarketContract = (withSignerIfPossible = true) => {
  const { library, account } = useActiveWeb3React();
  const signer = useMemo(
    () => (withSignerIfPossible ? getProviderOrSigner(library, account) : null),
    [withSignerIfPossible, library, account]
  );
  return useMemo(() => getMarketPlaceContract(signer), [signer]);
};

export function useTokenContract(tokenAddress, withSignerIfPossible) {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
}
