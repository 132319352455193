import { ArrowLeft } from "@styled-icons/entypo";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetchPackage } from "state/package/hooks";
import {
  useCurrentRewards,
  useFetchMarketPlace,
  useFetchUserAsyncData,
  useFetchUserRewardsAsync,
} from "state/user/hooks";
import styled from "styled-components";
import BirdList from "./components/BirdList";
import SellList from "./components/SellList";
import useEagerConnect from "hooks/useEagerConnect";
import { Box, Flex, Heading, Text, useModal } from "@pancakeswap/uikit";
import useTokenBalanceDisplay from "hooks/useTokenBalanceDisplay";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import tokens from "config/constants/tokens";
import RechargeModal from "./components/RechargeModal";

const MarketWrapper = styled.div`
  .back-icon {
    top: 23px;
    width: 30px;
    height: 30px;
    position: absolute;
    cursor: pointer;
  }

  .current-reward {
    position: absolute;
    top: 30px;
    right: 30px;
    display: flex;
    gap: 5px;

    img {
      width: 20px;
    }
  }

  .title {
    font-weight: 400;
    font-size: 28px;
    line-height: 1.5;
    align-items: center;
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
    text-shadow: 2px 0 #252424a9, -2px 0 #252424a9, 0 2px #252424a9,
      0 -2px #252424a9, 1px 1px #252424a9, -1px -1px #252424a9,
      1px -1px #252424a9, -1px 1px #252424a9;
  }

  .navigate-button {
    display: flex;
    justify-content: center;

    .top-board {
      background: rgba(201, 187, 187, 0.15);
      border-radius: 10px;
    }

    button {
      outline: 0;
      border: 0;
      height: 36px;
      padding: 8px;
      border-radius: 8px;
      background: none;
      font-size: 12px;
      color: #fff;
      font-family: "RetroGaming", sans-serif;
      cursor: pointer;
    }

    .active {
      background: linear-gradient(133.23deg, #fffaef 3.43%, #ffffff 96.74%);
      transition: all 0.2;
      color: #006037;
      font-weight: 600;
    }
  }

  .top-list {
    margin-top: 20px;
  }

  .sort-select {
    position: relative;

    .select-dropdown {
      position: absolute;
      transition: 0.2;
      z-index: 99;
    }
  }
`;

const StyledImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const Swich = {
  BUY: "Buy",
  SELL: "Sell",
  ITEMS: "Items",
};

const CustomFlex = styled(Flex)`
  cursor: pointer;
`;

const MarkerPlace = () => {
  // useEagerConnect();
  useFetchUserAsyncData();
  useFetchMarketPlace();
  useFetchPackage();
  useFetchUserRewardsAsync();
  const { chainId } = useActiveWeb3React();
  const { balanceDisplay: balanceSVC } = useTokenBalanceDisplay(
    tokens.SVC[chainId]
  );
  const { balanceDisplay: balanceSVG } = useTokenBalanceDisplay(
    tokens.SVG[chainId]
  );
  const [switchValue, setSwitchValue] = useState(Swich.BUY);
  const navigate = useNavigate();

  const currentRewards = useCurrentRewards();

  const [onPresentModal] = useModal(<RechargeModal />);

  const renderContent = () => {
    if (switchValue === Swich.BUY) {
      return <BirdList />;
    }
    if (switchValue === Swich.SELL) {
      return <SellList />;
    }
    // if (switchValue === Swich.ITEMS) {
    //   return <ItemList />;
    // }
  };

  return (
    <Box
      width={"100%"}
      height={"auto"}
      minHeight={"100vh"}
      backgroundImage={['url("/imgs/background.png")']}
      backgroundSize={"cover"}
      backgroundRepeat={"no-repeat"}
      backgroundPosition={["center", "center"]}
      position="relative"
    >
      <Box
        py={"24px"}
        mx="auto"
        height={"auto"}
        minHeight={"100vh"}
        width={"100%"}
        maxWidth="1200px"
      >
        <Box marginTop={["70px", "70px", "20px"]}>
          <Heading
            as="h1"
            scale="xl"
            color="white"
            data-test="nft-collections-title"
            textAlign={"center"}
          >
            Market place
          </Heading>
        </Box>
        <Box mx="auto">
          <MarketWrapper>
            <div className="back-icon" onClick={() => navigate("/menu")}>
              <ArrowLeft color="white" />
            </div>
            <Flex
              flexDirection={["column", "column", "row"]}
              className="current-reward"
            >
              <Flex marginRight={"20px"} justifyContent="flex-end">
                <StyledImage src="/imgs/SVC.png" alt="" />
                <Text fontSize="16px" color="white">
                  {balanceSVC}
                </Text>
              </Flex>
              <Box display={["none", "none", "block"]}>
                <CustomFlex
                  onClick={onPresentModal}
                  marginRight={"20px"}
                  justifyContent="flex-end"
                >
                  <StyledImage src="/imgs/SVG.png" alt="" />
                  <Text fontSize="16px" color="white">
                    {balanceSVG}
                  </Text>
                </CustomFlex>
              </Box>
              <CustomFlex
                onClick={onPresentModal}
                marginRight={"20px"}
                justifyContent="flex-end"
              >
                <StyledImage src="/imgs/gold.png" alt="" />
                <Text fontSize="16px" color="white">
                  {currentRewards}
                </Text>
              </CustomFlex>
            </Flex>
            <div className="navigate-button">
              <div className="top-board">
                <button
                  className={switchValue === Swich.BUY ? "active" : ""}
                  onClick={() => setSwitchValue(Swich.BUY)}
                >
                  Buy
                </button>
                {/*  <button
                  className={switchValue === Swich.ITEMS ? "active" : ""}
                  onClick={() => setSwitchValue(Swich.ITEMS)}
                >
                  Items
                </button>*/}
                <button
                  className={switchValue === Swich.SELL ? "active" : ""}
                  onClick={() => setSwitchValue(Swich.SELL)}
                >
                  Sell
                </button>
              </div>
            </div>
          </MarketWrapper>
        </Box>
        <Box padding={["16px", "24px"]} mx="auto">
          {renderContent()}
        </Box>
      </Box>
    </Box>
  );
};

export default MarkerPlace;
