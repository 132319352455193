import { createContext, useCallback, useState } from "react";
import kebabCase from "lodash/kebabCase";
import { toastTypes } from "common/components";

export const ToastsContext = createContext(undefined);

export const ToastsProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const toast = useCallback(
    ({ title, description, type }) => {
      setToasts((prevToasts) => {
        const id = kebabCase(title);

        // Remove any existing toasts with the same id
        const currentToasts = prevToasts.filter(
          (prevToast) => prevToast.id !== id
        );

        return [
          {
            id,
            title,
            description,
            type,
          },
          ...currentToasts,
        ];
      });
    },
    [setToasts]
  );

  const toastError = useCallback(
    (title, description) => {
      return toast({ title, description, type: toastTypes.DANGER });
    },
    [toast]
  );

  const toastInfo = useCallback(
    (title, description) => {
      return toast({ title, description, type: toastTypes.INFO });
    },
    [toast]
  );

  const toastSuccess = useCallback(
    (title, description) => {
      return toast({ title, description, type: toastTypes.SUCCESS });
    },
    [toast]
  );

  const toastWarning = useCallback(
    (title, description) => {
      return toast({ title, description, type: toastTypes.WARNING });
    },
    [toast]
  );

  const clear = useCallback(() => setToasts([]), []);
  const remove = useCallback((id) => {
    setToasts((prevToasts) =>
      prevToasts.filter((prevToast) => prevToast.id !== id)
    );
  }, []);

  return (
    <ToastsContext.Provider
      value={{
        toasts,
        clear,
        remove,
        toastError,
        toastInfo,
        toastSuccess,
        toastWarning,
      }}
    >
      {children}
    </ToastsContext.Provider>
  );
};
