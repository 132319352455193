import { CANVAS_HEIGHT, CANVAS_WIDTH, PipeSpeed } from "../../data/constants";

export default class Reward {
  constructor(p5, gold) {
    this.p5 = p5;
    this.image = gold;
    this.startX = 0;
    this.gravity = 0.4;
    this.velocity = 0;
    this.lift = -8;
    this.goldPosition = {
      y: CANVAS_HEIGHT / 2 + 20,
      x: CANVAS_WIDTH / 2 - 20,
    };
    this.offset = -10;
    this.birdRotate = { angle: 0, xOffset: 0, yOffset: 0 };
    this.numberPosition = {
      x: CANVAS_WIDTH,
      y: CANVAS_HEIGHT / 2,
    };
    this.reward = 0;
  }

  async draw(gameText, rewardAmound) {
    if (rewardAmound > 0) {
      this.reward = rewardAmound;
    }

    gameText.rewardAmount(
      rewardAmound,
      this.numberPosition.x,
      this.numberPosition.y
    );
    this.p5.push();
    this.p5.translate(this.goldPosition.x, this.goldPosition.y);
    this.p5.image(this.image, this.offset, 0, 30, 30);
    this.p5.image(this.image, this.offset + 40, 20, 30, 30);
    this.p5.image(this.image, this.offset + 80, 10, 30, 30);
    this.p5.image(this.image, this.offset - 30, 50, 30, 30);

    this.p5.pop();
  }
  update() {
    this.velocity += this.gravity;
    this.offset -= PipeSpeed + 2;
    this.goldPosition.y += this.velocity;
    this.numberPosition.y -= 1;
    this.numberPosition.x -= PipeSpeed + 2;
  }

  resetGoldAnimation() {
    this.goldPosition = {
      y: CANVAS_HEIGHT / 2 - 10,
      x: CANVAS_WIDTH / 2 - 20,
    };
    this.velocity = 0;
    this.offset = -20;
    this.numberPosition = {
      x: CANVAS_WIDTH,
      y: CANVAS_HEIGHT / 2,
    };
  }
}
