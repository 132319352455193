import { BirdType } from "config/constants";

const { createSelector } = require("@reduxjs/toolkit");

const classifyPackage = (data) => {
  return data.map((el) => {
    let birdType;
    if (el?.birdId === 1) {
      birdType = BirdType.FREE;
    } else if (el?.birdId === 2) {
      birdType = BirdType.STANDARD;
    } else if (el?.birdId === 3) {
      birdType = BirdType.LUXURY;
    } else if (el?.birdId === 4) {
      birdType = BirdType.PREMIUM;
    }

    return {
      ...el,
      birdType,
    };
  });
};

export const packageSelector = createSelector(
  (state) => state.package.list.data,
  classifyPackage
);
