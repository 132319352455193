import { CANVAS_HEIGHT, CANVAS_WIDTH } from "../../data/constants";

export class GameButtonNew {
  constructor(x, y, text, spriteImage) {
    this.text = text;
    this.image = spriteImage;
    this.x = x;
    this.y = y;
    this.width = 160;
    this.height = 60;
  }
  draw(p5) {
    p5.image(
      this.image,
      this.x,
      this.y,
      this.width,
      this.height,
      200,
      170,
      160,
      60
    );

    p5.textSize(20);
    const savedAlign = p5.textAlign();
    p5.textAlign('center', 'center');
    p5.text(this.text, this.x, this.y, this.width, this.height - 8);
    p5.textAlign(savedAlign.horizontal, savedAlign.vertical);
  }
  
  hitTest(mouseX, mouseY) {
    return (mouseX > this.x && mouseX < (this.x + this.width)
    && mouseY > this.y && mouseY < (this.y + this.height))
  }
}

export class GameButton {
  constructor(p5, text, spriteImage, windowSize) {
    this.p5 = p5;
    this.gameText = text;
    this.image = spriteImage;
    this.windowSize = windowSize;
    this.isMobile = false;
    if (this.windowSize.width < CANVAS_WIDTH / 2) {
      this.isMobile = true;
    }
  }

  resetButton() {
    this.x = CANVAS_WIDTH / 2 - 85;
    this.y = CANVAS_HEIGHT / 2 + 150;
    this.p5.image(
      this.image,
      this.x,
      this.y,
      160,
      60,
      200,
      170,
      160,
      60
    );
    this.gameText.resetText();
  }

  backToHomeButton() {
    this.p5.image(
      this.image,
      CANVAS_WIDTH / 2 - 85,
      CANVAS_HEIGHT / 2 + 180,
      160,
      60,
      200,
      170,
      160,
      60
    );
    this.gameText.backToHomeText();
  }

  revivalButton() {
    let xPosition = CANVAS_WIDTH / 2 - 85 - 150;
    if (this.isMobile) {
      xPosition = CANVAS_WIDTH / 2 - 85;
    }
    this.p5.image(
      this.image,
      xPosition,
      CANVAS_HEIGHT / 2 + 50,
      160,
      60,
      200,
      170,
      160,
      60
    );
    this.gameText.revivalText(this.isMobile);
  }

  restartButton() {
    let xPosition = CANVAS_WIDTH / 2 - 85 + 150;
    let YPosition = 50;
    if (this.isMobile) {
      xPosition = CANVAS_WIDTH / 2 - 85;
      YPosition = 120;
    }
    this.p5.image(
      this.image,
      xPosition,
      CANVAS_HEIGHT / 2 + YPosition,
      160,
      60,
      200,
      170,
      160,
      60
    );
    this.gameText.restartText(this.isMobile);
  }
}
