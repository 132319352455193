import { Outlet } from "react-router-dom";
import styled from "styled-components";

export const LayoutWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #60cbe8;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const WithoutGameLayoutStyled = styled.div`
  width: 100%;
  border-radius: 8px;
  max-width: 960px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/imgs/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

const WithoutGameLayout = () => {
  return (
    <LayoutWrapper>
      <WithoutGameLayoutStyled>
        <Outlet />
      </WithoutGameLayoutStyled>
    </LayoutWrapper>
  );
};

export default WithoutGameLayout;
