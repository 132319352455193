import { useCallback, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { Alert, alertVariants } from "@pancakeswap/uikit";

export const alertTypeMap = {
  info: alertVariants.INFO,
  success: alertVariants.SUCCESS,
  danger: alertVariants.DANGER,
  warning: alertVariants.WARNING,
};

const StyledToast = styled.div`
  right: 16px;
  position: fixed;
  max-width: calc(100% - 32px);
  transition: all 250ms ease-in;
  width: 300px;
`;

const Toast = ({ toast, onRemove, style, ttl, ...props }) => {
  const timer = useRef();
  const { id, title, description, type } = toast;

  const handleRemove = useCallback(() => onRemove(id), [id, onRemove]);

  const handleMouseEnter = () => {
    clearTimeout(timer.current);
  };

  const handleMouseLeave = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = window.setTimeout(() => {
      handleRemove();
    }, ttl);
  };

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = window.setTimeout(() => {
      handleRemove();
    }, ttl);

    return () => {
      clearTimeout(timer.current);
    };
  }, [timer, ttl, handleRemove]);

  return (
    <CSSTransition timeout={250} style={style} {...props}>
      <StyledToast
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Alert
          title={title}
          variant={alertTypeMap[type]}
          onClick={handleRemove}
        >
          {description}
        </Alert>
      </StyledToast>
    </CSSTransition>
  );
};

export default Toast;
