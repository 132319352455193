import { axiosApiInstance, AUTH_ENDPOINT } from "endpoint/index";

const getSignatureLogin = async () => {
  const response = await axiosApiInstance.get(
    `${AUTH_ENDPOINT}/get-message-sign`
  );
  return response.data;
};

const postSignatureLogin = async (sign) => {
  const response = await axiosApiInstance.post(
    `${AUTH_ENDPOINT}/login/wallet`,
    {
      sign,
    }
  );
  return response.data;
};

export { getSignatureLogin, postSignatureLogin };
