import AppContext from "contexts/AppContext";
import { useContext } from "react";
import styled from "styled-components";
import Game, { GameData } from "./Game";
import { LayoutWrapper } from "./WithoutGameLayout";

const GameBox = styled.div`
  width: fit-content;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  @media screen and (max-width: 960px) {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const GamePage = ({ gameData }) => {
  const { currentBird } = useContext(AppContext);
  gameData.currentBird = currentBird;
  return (
    <LayoutWrapper className="noselect">
        <Game gameData={gameData} />
    </LayoutWrapper>
  );
};

export default GamePage;
