import { CANVAS_HEIGHT, CANVAS_WIDTH } from "../../data/constants";

export default class GameArt {
  constructor(p5, text, goldImage, heartImage, manaImage, windowSize) {
    this.p5 = p5;
    this.gameText = text;
    this.goldImage = goldImage;
    this.heartImage = heartImage;
    this.manaImage = manaImage;
    this.windowSize = windowSize;
    this.responsivePosition = 0;
    if (this.windowSize.width < CANVAS_WIDTH) {
      this.responsivePosition = CANVAS_WIDTH - this.windowSize.width;
    }
  }

  goldReward(rewardInfo, birdType) {
    let reward = rewardInfo;
    let isFreeBird = false;
    if (birdType === "free") {
      reward = "No reward";
      isFreeBird = true;
    }
    this.p5.image(this.goldImage, 20 + this.responsivePosition / 2, 20, 25, 25);
    this.gameText.rewardInfo(reward, this.responsivePosition / 2, isFreeBird);
  }

  heartLive(heart) {
    this.p5.image(
      this.heartImage,
      18 + this.responsivePosition / 2,
      55,
      30,
      30
    );
    this.gameText.liveInfo(heart, this.responsivePosition / 2);
  }

  manaBar(totalMana, curentMana) {
    this.p5.image(this.manaImage, 18 + this.responsivePosition / 2, 90, 30, 30);
    this.gameText.manaInfo(totalMana, curentMana, this.responsivePosition / 2);
  }
}
