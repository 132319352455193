const contracts = {
  marketPlace: {
    97: "0xA45fC41e91225C5B8be10D7Cf86B1C6b1EaeC1e2",
    56: "0x22546A779C21F3e69C00E4b21B4c80Cc2499FC53",
    137: "0xC10739Ce44ebdB04a3F78dd24Fc2Ad7e0B36a76e",
    80001: "0x6ad62E0811e58f53E0cF07f6B68DE90c86D041FA",
  },
  birdNFT: {
    97: "0xBbc022ac45749356eEBa1132801F845E9DCf839E",
    56: "0x34cfFD5Dc73A9A69638d369cd0311b6afE78e5f7",
    137: "0x2327d3912dd1dE745AD1CcD733848D3f6e144Fa1",
    80001: "0xCd2B469Bd09FbF53CFb81FB70310573D6a39444f",
  },
};

export default contracts;
