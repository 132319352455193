/* eslint-disable no-undef */
import { Modal, Flex, Text, Box } from "@pancakeswap/uikit";
import { Button } from "common/components";
import useBuyPackage from "../hooks/useBuyPackage";

const BuyTurnModal = ({ title, birdData, onConfirm, onDismiss }) => {
  const { onBuyPackage } = useBuyPackage();

  return (
    <Modal
      headerBackground="gradients.cardHeader"
      title={title}
      onDismiss={onDismiss}
    >
      <Flex>
        {birdData.packagesOf.map((pkg) => (
          <Box
            key={pkg.id}
            background="white"
            margin={10}
            padding={10}
            borderRadius="8px"
            border={"1px solid black"}
          >
            <Flex flexDirection={"column"} alignItems="center">
              <Text fontWeight={700}>{pkg.turnNumber} turns</Text>
              <Text className="flex">
                <Box marginRight={"5px"} className="flex item-center ">
                  <img width={16} height={16} src="/imgs/gold.png" alt="" />
                </Box>
                <div className="text text--tilte">{pkg.price}</div>
              </Text>
              <Button
                width={50}
                scale="xs"
                onClick={() => onBuyPackage(birdData.nftId, pkg)}
              >
                Buy
              </Button>
            </Flex>
          </Box>
        ))}
      </Flex>
      <Flex justifyContent={"center"}>
        <Button width={60} height={40} scale="xs" onClick={onDismiss}>
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
};

export default BuyTurnModal;
