// export const {
//   CANVAS_HEIGHT,
//   CANVAS_WIDTH,
//   MIN_PIPE_GAP,
//   MAX_PIPE_GAP,
//   MIN_PIPE_HEIGHT,
//   MAX_PIPE_HEIGHT,
//   PIPE_WIDTH,
//   MinPipeOffset,
//   MaxPipeOffset,
//   PipeSpeed,
//   BIRDSIZE,
//   FLOOROFFSET,
//   GRAVITY,
//   VELOCITY,
//   LIFT,
//   FRAMERATE,
// } = GAME_CONFIG;

export const BIRDANIMATIONFRAME = [0, 60, 120];
export const CANVAS_HEIGHT = 600;
export const MIN_PIPE_GAP = 150;
export const MAX_PIPE_GAP = 180;
export const CANVAS_WIDTH = 960;
export const MIN_PIPE_HEIGHT = 100;
export const MAX_PIPE_HEIGHT = 250;
export const PIPE_WIDTH = 60;
export const MinPipeOffset = 180;
export const MaxPipeOffset = 250;
export const PipeSpeed = 3;
export const BIRDSIZE = { Width: 60, Height: 42 };
export const FLOOROFFSET = 60;
export const FRAMERATE = 60;
export const GRAVITY = 0.5;
export const VELOCITY = 0;
export const LIFT = -8;
