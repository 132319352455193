import { Flex, Text } from "@pancakeswap/uikit";
import React from "react";
import { Button } from "common";
import useListingNFT from "../../hooks/useListingNFT";
import { useCurrentListingNFT } from "state/user/hooks";
import _ from "lodash";
import { useModal } from "@pancakeswap/uikit";
import ListingModal from "../ListingModal";
import BuyTurnModal from "../BuyTurnModal";
import { StyledHotCollectionCard, StyledImage } from "./style";
import ConnectWalletButtonWrapper from "components/ConnectWalletButtonWrapper";

export default function SellCard({ birdData }) {
  const { nftId, mana, heart, remainTurn, uri, name } = birdData;

  const currentListingNFT = useCurrentListingNFT();

  const { onListingNFT } = useListingNFT(birdData);

  const [onPresentModal] = useModal(<ListingModal onConfirm={onListingNFT} />);

  const [onPresentBuyTurnModal] = useModal(
    <BuyTurnModal birdData={birdData} title={"Buy turns"} />
  );

  const isCurrentListingNFT =
    _.findIndex(currentListingNFT, (el) => el.nftId === nftId) >= 0;

  return (
    <StyledHotCollectionCard>
      <Flex position="relative" justifyContent="center" py="8px">
        <Flex width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <StyledImage width={100} height={100} src={uri} alt="" />
        </Flex>
        <Flex
          width={"100%"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          flexDirection="column"
        >
          <Text
            marginTop={"4px"}
            bold
            textTransform="uppercase"
            color="textSubtle"
            fontSize="16px"
          >
            {name}
          </Text>
          <Text marginTop={"4px"} fontSize="12px" color="black">
            Live: {heart}
          </Text>
          <Text marginTop={"4px"} fontSize="12px" color="black">
            Mana: {mana}
          </Text>
          <Text marginTop={"4px"} fontSize="12px" color="black">
            Turns: {remainTurn}
          </Text>
          <Flex>
            <ConnectWalletButtonWrapper>
              <Button
                scale="xs"
                isLoading={isCurrentListingNFT}
                onClick={onPresentModal}
              >
                {isCurrentListingNFT ? "Listing" : "List"}
              </Button>
              <Button scale="xs" onClick={onPresentBuyTurnModal}>
                {"Buy turn"}
              </Button>
            </ConnectWalletButtonWrapper>
          </Flex>
        </Flex>
      </Flex>
    </StyledHotCollectionCard>
  );
}
