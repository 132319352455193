import { Modal } from "@pancakeswap/uikit";
import { Button } from "common/components";

const CustomModal = ({ title, onConfirm, onDismiss }) => {
  return (
    <Modal
      headerBackground="gradients.cardHeader"
      title={title}
      onDismiss={onDismiss}
    >
      <Button
        onClick={() => {
          onConfirm();
          onDismiss();
        }}
      >
        Confirm
      </Button>
      <Button onClick={onDismiss}>Cancel</Button>
    </Modal>
  );
};

export default CustomModal;
