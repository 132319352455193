import { axiosApiInstance, USER_ENDPOINT } from "endpoint/index";

const postSignatureListing = async (tokenId, sign) => {
  const response = await axiosApiInstance.post(`${USER_ENDPOINT}/store-sign`, {
    tokenId,
    sign,
  });
  return response.data;
};

export default postSignatureListing;
