import { createSlice, createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance, USER_ENDPOINT } from "endpoint/index";
import camelcaseKeys from "camelcase-keys";
import _ from "lodash";
import { parseEther } from "@ethersproject/units";
import { getMyReward } from "endpoint";

const otherAction = createAction("updateCounterByValue");

const initialState = {
  userBirds: {
    data: [],
    isLoading: false,
  },
  order: {
    data: [],
    isLoading: false,
  },
  listing: {
    data: [],
    isLoading: false,
  },
  market: {
    data: [],
    isLoading: false,
  },
  currentReward: {
    reward: 0,
    highScore: 0,
    isLoading: false,
  },
  walletAddress: null, // addresss is set in database of current user
};

export const fetchUserRewardAsync = createAsyncThunk(
  "User/fetchUserRewardAsync",
  async () => {
    const data = await getMyReward();
    return camelcaseKeys(data, { deep: true });
  }
);

export const fetchUserDataAsync = createAsyncThunk(
  "User/fetchUserData",
  async () => {
    const response = await axiosApiInstance.get(`${USER_ENDPOINT}/my-nft`);
    return camelcaseKeys(response.data, { deep: true });
  }
);

export const fetchMarketPlaceAsync = createAsyncThunk(
  "User/fetchMarketPlace",
  async () => {
    const response = await axiosApiInstance.get(`${USER_ENDPOINT}/listed-nft`);
    return camelcaseKeys(response.data, { deep: true });
  }
);

const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    listingNFTPending: (state, { payload }) => {
      const listingNFT = state.userBirds.data.find(
        (el) => el.nftId === payload.nftId
      );
      // Loading
      state.listing.isLoading = true;
      // // Remove listing item in userBirds data
      // _.remove(state.data.userBirds, (el) => el.nftId === payload.tokenId);
      // Add listing item to listing data
      state.listing.data.push(listingNFT);
    },
    listingNFTFulfilled: (state, { payload }) => {
      // const listingNFT = state.userBirds.data.find(
      //   (el) => el.nftId === payload.nftId
      // );
      // Loading
      state.listing.isLoading = false;
      // Remove listing item to listing data
      _.remove(state.listing.data, (el) => el.nftId === payload.nftId);
      // Remove listing item in userBirds data
      _.remove(state.userBirds.data, (el) => el.nftId === payload.nftId);
      // state.market.data.push({
      //   ...listingNFT,
      //   price: parseEther(payload.price),
      //   owner: payload.owner,
      // });
    },
    listingNFTRejected: (state, { payload }) => {
      // Remove listing item to listing data
      _.remove(state.listing.data, (el) => el.nftId === payload.nftId);
      // Loading
      state.listing.isLoading = false;
    },
    removeAll: (state) => {
      state.userBirds.data = [];
    },
    buyNFTPending: (state, { payload }) => {
      const orderNFT = state.market.data.find(
        (el) => el.nftId === payload.nftId
      );
      state.order.isLoading = true;
      state.order.data.push(orderNFT);
    },
    buyNFTFulfilled: (state, { payload }) => {
      // 1) Find order in market
      // const orderNFT = state.market.data.find(
      //   (el) => el.nftId === payload.nftId
      // );
      // 2) Push order to user list
      // state.userBirds.data.push(orderNFT);

      // 3) Remove order bird in order list
      _.remove(state.order.data, (el) => el.nftId === payload.nftId);

      // 4) Remove order in market
      _.remove(state.market.data, (el) => el.nftId === payload.nftId);

      // 5) Loading order = false
      state.order.isLoading = false;
    },
    buyNFTRejected: (state, { payload }) => {
      _.remove(state.order.data, (el) => el.nftId === payload.nftId);
      state.order.isLoading = false;
    },
    cancelListingSuccess: (state, { payload }) => {
      // const cancelNFT = state.market.data.find(
      //   (el) => el.nftId === payload.nftId
      // );
      // state.userBirds.data.push(cancelNFT);
      _.remove(state.market.data, (el) => el.nftId === payload.nftId);
    },
    setWalletAddress: (state, { payload }) => {
      state.walletAddress = payload;
    },
    clearUserData: (state, { payload }) => {
      state.userBirds.data = [];
      state.order.data = [];
      state.listing.data = [];
      state.currentReward.reward = [];
      state.walletAddress = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDataAsync.fulfilled, (state, action) => {
        state.userBirds.data = action.payload;
        state.userBirds.isLoading = false;
      })
      .addCase(fetchUserDataAsync.rejected, (state, action) => {
        state.userBirds.data = [];
        state.userBirds.isLoading = false;
      })
      .addCase(fetchUserDataAsync.pending, (state, action) => {
        state.userBirds.isLoading = true;
      })
      .addCase(otherAction, (state, action) => {});

    builder
      .addCase(fetchMarketPlaceAsync.pending, (state, { payload }) => {
        state.market.isLoading = true;
      })
      .addCase(fetchMarketPlaceAsync.fulfilled, (state, { payload }) => {
        state.market.data = payload;
        state.market.isLoading = false;
      })
      .addCase(fetchMarketPlaceAsync.rejected, (state, { payload }) => {
        state.market.data = [];
        state.market.isLoading = false;
      });

    builder.addCase(fetchUserRewardAsync.pending, (state, { payload }) => {
      state.currentReward.isLoading = true;
    });
    builder.addCase(fetchUserRewardAsync.fulfilled, (state, { payload }) => {
      state.currentReward.isLoading = false;
      state.currentReward.reward = payload.reward;
      state.currentReward.highScore = payload.highScore;
    });
    builder.addCase(fetchUserRewardAsync.rejected, (state, { payload }) => {
      state.currentReward.isLoading = false;
      state.currentReward.reward = 0;
      state.currentReward.highScore = 0;
    });

    builder.addDefaultCase((state, action) => {});
  },
});

const {
  listingNFTPending,
  listingNFTFulfilled,
  listingNFTRejected,
  removeAll,
  buyNFTPending,
  buyNFTFulfilled,
  buyNFTRejected,
  cancelListingSuccess,
  setWalletAddress,
  clearUserData,
} = userSlice.actions;

export { removeAll, cancelListingSuccess, setWalletAddress, clearUserData };

export const listingNFT = {
  pending: listingNFTPending,
  fulfilled: listingNFTFulfilled,
  rejected: listingNFTRejected,
};

export const buyNFT = {
  pending: buyNFTPending,
  fulfilled: buyNFTFulfilled,
  rejected: buyNFTRejected,
};

export default userSlice.reducer;
