import { Flex, Text } from "@pancakeswap/uikit";
import React from "react";
import { Button } from "common";
import { useCurrentOrderNFT } from "state/user/hooks";
import _ from "lodash";
import { useModal } from "@pancakeswap/uikit";
import useBuyNFT from "components/Market/hooks/useBuyNFT";
import useCancelListing from "components/Market/hooks/useCancelListing";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { formatEther } from "@ethersproject/units";
import CustomModal from "../CustomModal";
import { StyledHotCollectionCard, StyledImage } from "./style";
import useApproveCallback, { ApprovalState } from "hooks/useApproveCallback";
import contracts from "config/constants/contracts";
import tokens from "config/constants/tokens";
import { BigNumber } from "@ethersproject/bignumber";
import ConnectWalletButtonWrapper from "components/ConnectWalletButtonWrapper";

export default function BuyCard({ birdData }) {
  const { chainId } = useActiveWeb3React();
  const { nftId, heart, mana, price, owner, uri, name, remainTurn } = birdData;

  const { onBuyNFT } = useBuyNFT(birdData);
  const { onCancelListing, isLoading } = useCancelListing(birdData);
  const { account } = useActiveWeb3React();
  const priceEther = formatEther(price);
  const isOwner = owner?.toLowerCase() === account?.toLowerCase();
  const [approvalSVC, approveSVCCallback] = useApproveCallback(
    BigNumber.from(price),
    tokens.SVC[chainId],
    contracts.marketPlace[chainId]
  );

  const currentOrderNFTs = useCurrentOrderNFT();

  const [onPresentBuyModal] = useModal(
    <CustomModal onConfirm={onBuyNFT} title="Buy NFT" />
  );

  const [onPresentCancelModal] = useModal(
    <CustomModal onConfirm={onCancelListing} title="Cancel listing" />
  );

  const isCurrentOrderNFT =
    _.findIndex(currentOrderNFTs, (el) => el.nftId === nftId) >= 0;

  return (
    <StyledHotCollectionCard>
      <Flex position="relative" justifyContent="center" py="8px">
        <Flex width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <StyledImage width={100} height={100} src={uri} alt="" />
        </Flex>
        <Flex
          width={"100%"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          flexDirection="column"
        >
          <Text
            marginTop={"4px"}
            bold
            textTransform="uppercase"
            color="textSubtle"
            fontSize="16px"
          >
            {name}
          </Text>
          <Text marginTop={"4px"} fontSize="12px" color="black">
            Live: {heart}
          </Text>
          <Text marginTop={"4px"} fontSize="12px" color="black">
            Mana: {mana}
          </Text>
          <Text marginTop={"4px"} fontSize="12px" color="black">
            Turns: {remainTurn}
          </Text>
          <Flex alignItems={"center"} width={"100%"}>
            <StyledImage
              marginRight={"4px"}
              width={20}
              height={20}
              src={"/imgs/SVC.png"}
              alt=""
            />
            <Text marginTop={"4px"} fontSize="12px" color="black">
              {priceEther}
            </Text>
          </Flex>
          <ConnectWalletButtonWrapper>
            {isOwner ? (
              <Button
                scale="xs"
                isLoading={isLoading}
                onClick={onPresentCancelModal}
              >
                {isLoading ? "Unlisting" : "Unlist"}
              </Button>
            ) : approvalSVC === ApprovalState.PENDING ||
              approvalSVC === ApprovalState.NOT_APPROVED ? (
              <Button
                scale="xs"
                isLoading={approvalSVC === ApprovalState.PENDING}
                onClick={approveSVCCallback}
              >
                {approvalSVC === ApprovalState.PENDING ? "Enabling" : "Enable"}
              </Button>
            ) : (
              <Button
                scale="xs"
                isLoading={isCurrentOrderNFT}
                onClick={onPresentBuyModal}
              >
                {isCurrentOrderNFT ? "Buying" : "Buy"}
              </Button>
            )}
          </ConnectWalletButtonWrapper>
        </Flex>
      </Flex>
    </StyledHotCollectionCard>
  );
}
