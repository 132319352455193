import React from "react";
import { useIsLoadingMyNFTs, useUserNFT } from "state/user/hooks";
import { SellCard, ListCards } from "./ListCards";

const SellList = () => {
  const userNFT = useUserNFT();
  const isLoading = useIsLoadingMyNFTs();

  return (
    <ListCards
      isLoading={isLoading}
      items={userNFT}
      renderItem={(item) => <SellCard birdData={item} />}
    />
  );
};

export default SellList;
