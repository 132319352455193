import rewardSound from "./reward.mp3";

export const jumpAudio = () => {
  const audio = document.getElementById("jumping-sound");
  audio.muted = false;
  if (audio.paused) {
    audio.play();
  } else {
    audio.currentTime = 0;
  }
};

export const crashAudio = () => {
  const audio = document.getElementById("crash-sound");
  audio.muted = false;
  if (audio.paused) {
    audio.play();
  } else {
    audio.currentTime = 0;
  }
};

export const getRewardAudio = () => {
  const audio = document.getElementById("reward-sound");
  audio.muted = false;
  if (audio.paused) {
    audio.play();
  } else {
    audio.currentTime = 0;
  }
};
