import {
  bgrSound,
  getRewardSound,
  jumpSound,
  crashSound,
} from "components/audio";

export const Sound = () => {
  return (
    <>
      <audio id="reward-sound" controls muted src={getRewardSound} hidden />
      <audio id="jumping-sound" controls muted src={jumpSound} hidden />
      <audio id="crash-sound" controls muted src={crashSound} hidden />
      <audio
        id="background-sound"
        controls
        muted
        src={bgrSound}
        autoPlay
        loop
        hidden
      />
    </>
  );
};

export default Sound;
