import { axiosApiInstance, USER_ENDPOINT } from "endpoint/index";

const getSignatureBuy = async (tokenId) => {
  const response = await axiosApiInstance.get(
    `${USER_ENDPOINT}/get-sign/${tokenId}`
  );
  return response.data;
};

export default getSignatureBuy;
