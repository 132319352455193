import memoize from "lodash/memoize";
import { AddressZero } from "@ethersproject/constants";
import { getAddress } from "@ethersproject/address";
import { Contract } from "@ethersproject/contracts";
import { simpleRpcProvider } from "./providers";

// returns the checksummed address if the address is valid, otherwise returns false
export const isAddress = memoize((value) => {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
});

// account is not optional
export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(library, account = "") {
  return account ? getSigner(library, account) : library;
}

export const ChainId = {
  BSC: 57,
  BSC_TESTNET: 97,
};

// account is optional
export function getContract(address, ABI, signer) {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, signer ?? simpleRpcProvider);
}
