import {
  BIRDANIMATIONFRAME,
  BIRDSIZE,
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  FLOOROFFSET,
  GRAVITY,
  LIFT,
  PipeSpeed,
  PIPE_WIDTH,
  VELOCITY
} from "../../data/constants";
import { jumpAudio } from "../audio/soundManager";

export default class Bird {
  constructor(p5, spriteImage, birdMana, birdLive) {
    this.p5 = p5;
    this.gravity = GRAVITY;
    this.velocity = VELOCITY;
    this.lift = LIFT;
    this.birdPosition = {
      y: CANVAS_HEIGHT / 2 - BIRDSIZE.Width / 2,
      x: CANVAS_WIDTH / 2 - BIRDSIZE.Height / 2,
    };
    this.image = spriteImage;
    this.frame = 0;
    this.dead = false;
    this.birdRotate = { angle: 0, xOffset: 0, yOffset: 0 };
    this.birdMana = birdMana;
    this.birdLive = birdLive;
    this.animationFrame = 0;
    this.countPixel = 0;
  }

  reduceBirdLive = () => {
    if (this.birdLive >= 1) this.birdLive--;
  };

  resetBirdPosition = (pipes) => {
    let positionY;
    const pipeCenter = pipes.filter(
      (x) => x.offset - PIPE_WIDTH * 2 < this.birdPosition.x
    );
    if (pipes) {
      positionY = pipeCenter[0].height + pipeCenter[0].gap / 2;
    }

    this.birdPosition = {
      y: positionY,
      x: CANVAS_WIDTH / 2 - BIRDSIZE.Width / 2,
    };
  };

  handleCountPixel = (frameNumber) => {
    this.countPixel += frameNumber * PipeSpeed;
  };

  draw() {
    if (this.dead === false) this.frame++;
    this.animationFrame = Math.floor(this.frame / 8) % 4;

    this.p5.push();

    this.p5.translate(
      this.birdPosition.x + this.birdRotate.xOffset,
      this.birdPosition.y + this.birdRotate.yOffset
    );
    this.p5.rotate((Math.PI / 180) * this.birdRotate.angle);
    this.p5.image(
      this.image,
      0,
      0,
      BIRDSIZE.Width,
      BIRDSIZE.Height,
      BIRDANIMATIONFRAME[this.animationFrame],
      0,
      BIRDSIZE.Width,
      BIRDSIZE.Height
    );

    this.p5.pop();
  }

  jump() {
    this.animationFrame = 1;
    this.velocity = this.lift;
    this.birdRotate = { angle: -15, xOffset: -10, yOffset: 15 };
    this.birdMana--;
    jumpAudio();
  }

  isDead() {
    return this.birdPosition.y >= CANVAS_HEIGHT - BIRDSIZE.Height - FLOOROFFSET
      ? true
      : false;
  }

  update() {
    this.velocity += this.gravity;
    this.birdPosition.y += this.velocity;

    if (this.velocity > 8)
      this.birdRotate = { angle: 0, xOffset: 0, yOffset: 0 };
    if (this.velocity > 9)
      this.birdRotate = { angle: 22.5, xOffset: 12, yOffset: -10 };

    if (this.velocity > 11)
      this.birdRotate = { angle: 45, xOffset: 30, yOffset: -15 };

    if (this.velocity > 12)
      this.birdRotate = { angle: 67.5, xOffset: 45, yOffset: -10 };

    if (this.velocity > 15)
      this.birdRotate = { angle: 90, xOffset: 60, yOffset: -10 };

    if (this.isDead()) {
      this.birdPosition.y = CANVAS_HEIGHT - BIRDSIZE.Height - FLOOROFFSET;
      this.velocity = 0;
      this.dead = true;
      this.reduceBirdLive();
    }

    if (this.velocity > 15) this.velocity = 15;
  }
}
