import {
  Grid,
  ArrowBackIcon,
  ArrowForwardIcon,
  Text,
  Box,
  Skeleton,
} from "@pancakeswap/uikit";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

export const ITEMS_PER_PAGE = 9;

export const PageButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.2em;
  margin-bottom: 1.2em;
`;

export const Arrow = styled.div`
  color: #ffffff;
  padding: 0 20px;
  :hover {
    cursor: pointer;
  }
`;

const CenterBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledSkeleton = styled(Skeleton)`
  border-radius: 24px;
`;

export default function ListCards({ items, renderItem, isLoading }) {
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  useEffect(() => {
    let extraPages = 1;
    if (items.length % ITEMS_PER_PAGE === 0) {
      extraPages = 0;
    }
    setMaxPage(
      Math.max(Math.floor(items.length / ITEMS_PER_PAGE) + extraPages, 1)
    );
  }, [items]);

  if (isLoading && items.length === 0) {
    return (
      <CenterBox>
        <Text width={"100px"} color="#006037">
          Loading...
        </Text>
      </CenterBox>
    );
    // return (
    //   <Grid
    //     gridGap="16px"
    //     gridTemplateColumns={["1fr", "1fr", "repeat(2, 1fr)", "repeat(3, 1fr)"]}
    //     mb="32px"
    //     data-test="nft-collection-row"
    //   >
    //     {[...Array(6)].map((x, i) => (
    //       <StyledSkeleton
    //         marginX={["0xp", "0px", "20px"]}
    //         key={i}
    //         minHeight={150}
    //       />
    //     ))}
    //   </Grid>
    // );
  }

  if (items.length === 0) {
    return (
      <CenterBox>
        <Text width={"100px"} color="#006037">
          No birds
        </Text>
      </CenterBox>
    );
  }

  return (
    <>
      <Grid
        gridGap="16px"
        gridTemplateColumns={["1fr", "1fr", "repeat(2, 1fr)", "repeat(3, 1fr)"]}
        mb="32px"
        data-test="nft-collection-row"
      >
        {items
          .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)
          .map((item) => (
            <React.Fragment key={item?.nftId}>
              {renderItem(item)}
            </React.Fragment>
          ))}
      </Grid>
      {items.length > ITEMS_PER_PAGE - 1 && (
        <PageButtons>
          <Arrow
            onClick={() => {
              setPage(page === 1 ? page : page - 1);
            }}
          >
            <ArrowBackIcon color={page === 1 ? "textDisabled" : "#ffffff"} />
          </Arrow>
          <Text color="#ffffff">{`Page ${page} of ${maxPage}`}</Text>
          <Arrow
            onClick={() => {
              setPage(page === maxPage ? page : page + 1);
            }}
          >
            <ArrowForwardIcon
              color={page === maxPage ? "textDisabled" : "#ffffff"}
            />
          </Arrow>
        </PageButtons>
      )}
    </>
  );
}
