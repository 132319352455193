import { light, ModalProvider } from "@pancakeswap/uikit";
import { Web3ReactProvider } from "@web3-react/core";
import Footer from "components/Footer";
import { GameData } from "components/Game";
import { Sound } from "components/Sound";
import WithoutGameLayout from "components/WithoutGameLayout";
import { ToastListener, ToastsProvider } from "contexts/ToastsContext";
import { gapi } from "gapi-script";
import useListenAccountChanged from "hooks/useListenAccountChanged";
import { useContext, useEffect } from "react";
import { Provider } from "react-redux";
import { useNavigate, useRoutes } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { localStorageData } from "utils/localStorageManager";
import { useWindowSize } from "utils/useWindowSize";
import "./App.css";
import GamePage from "./components/GamePage";
import { LeaderBoard } from "./components/LeaderBoard";
import Login from "./components/Login";
import MarketPlace from "./components/Market";
import Menu from "./components/Menu";
import AppContext from "./contexts/AppContext";
import { getGameConfig } from "./endpoint";
import store, { persistor } from "./state/index";
import { loginWithGoogle } from "./utils/auth";
import "./utils/socket";
import socket from "./utils/socket";
import { getLibrary } from "./utils/web3React";
import { PersistGate } from "redux-persist/integration/react";

// const WithoutGameLayout = lazy(() => import("./components/WithoutGameLayout"));

gapi.load("client:auth2", () => {
  gapi.client.init({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    plugin_name: "chat",
  });
});

export const AppWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
`;

const gameData = new GameData();

function GlobalHooks() {
  // auto open metamask extension
  // useListenAccountChanged();
  return null;
}

function App() {
  const navigate = useNavigate();
  const { setIsLogin, isPlayBackgroundSound, setIsPlayBackgroundSound } =
    useContext(AppContext);
  // const size = useWindowSize();

  // useEffect(() => {
  //   if (size.width) {
  //     socket.emit("screenSize", size);
  //   }
  // }, []);

  useEffect(() => {
    const bgrSound = document.getElementById("background-sound");
    if (isPlayBackgroundSound) {
      bgrSound.muted = false;
      bgrSound.play();
    } else {
      bgrSound?.pause();
    }
  }, [isPlayBackgroundSound]);

  useEffect(() => {
    (async () => {
      if (localStorageData.googleInfo) {
        const response = await loginWithGoogle(
          JSON.parse(localStorageData.googleInfo)
        );
        if (response.status === "success") {
          navigate("/menu");
          setIsLogin(true);
        }
      }

      const GAME_CONFIG = await getGameConfig();
      localStorage.setItem("game_config", JSON.stringify(GAME_CONFIG.data));
    })();
  }, []);
  let element = useRoutes([
    {
      path: "/",
      element: <WithoutGameLayout />,
      children: [
        { path: "/", element: <Login /> },
        {
          path: "menu",
          element: <Menu />,
        },
        { path: "top-score", element: <LeaderBoard /> },
      ],
    },
    { path: "game", element: <GamePage gameData={gameData} /> },
    { path: "market-place", element: <MarketPlace /> },
  ]);

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={light}>
            <ToastsProvider>
              <ModalProvider>
                <GlobalHooks />
                <AppWrapper>
                  {element}
                  <Sound />
                  <Footer />
                </AppWrapper>
              </ModalProvider>
              <ToastListener />
            </ToastsProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </Web3ReactProvider>
  );
}

export default App;
