import BigNumber from "bignumber.js";
import { BIG_ZERO } from "utils/bigNumber";
import { useWeb3React } from "@web3-react/core";
import { FAST_INTERVAL } from "config";
import { useTokenContract } from "./useContract";
import { useSWRContract } from "./useSWRContract";

const useTokenBalance = (tokenAddress) => {
  const { account } = useWeb3React();

  const contract = useTokenContract(tokenAddress, false);
  const { data, status, ...rest } = useSWRContract(
    account
      ? {
          contract,
          methodName: "balanceOf",
          params: [account],
        }
      : null,
    {
      refreshInterval: FAST_INTERVAL,
    }
  );

  return {
    ...rest,
    fetchStatus: status,
    balance: data ? new BigNumber(data.toString()) : BIG_ZERO,
  };
};

export default useTokenBalance;
