const { useEffect } = require("react");
const { useDispatch, useSelector } = require("react-redux");
const { fetchPackageAsync } = require(".");
const { packageSelector } = require("./selectors");

export const useFetchPackage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPackageAsync());
  }, [dispatch]);
};

export const usePackages = () => {
  return useSelector(packageSelector);
};
