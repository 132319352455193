import { BigNumber } from "@ethersproject/bignumber";
import { useWeb3React } from "@web3-react/core";
import { useTokenContract } from "./useContract";
import { useSWRContract } from "./useSWRContract";

const useTokenAllowance = (tokenAddress, spender) => {
  const { account } = useWeb3React();

  const contract = useTokenContract(tokenAddress, false);
  const { data, status, ...rest } = useSWRContract(
    account
      ? {
          contract,
          methodName: "allowance",
          params: [account, spender],
        }
      : null
  );

  return {
    ...rest,
    fetchStatus: status,
    allowance: data ? BigNumber.from(data.toString()) : BigNumber.from("0"),
  };
};

export default useTokenAllowance;
