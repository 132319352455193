import React, { useEffect, useState } from "react";
import styled from "styled-components";

const BirdCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .sprite {
    width: 80px;
    height: 80px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .animatedDiv {
      width: 60px;
      height: 42px;
    }
  }

  .birdData {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .birdName {
    text-shadow: 2px 0 #252424a9, -2px 0 #252424a9, 0 2px #252424a9,
      0 -2px #252424a9, 1px 1px #252424a9, -1px -1px #252424a9,
      1px -1px #252424a9, -1px 1px #252424a9;
    text-transform: capitalize;
  }

  .live {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-shadow: 2px 0 #252424a9, -2px 0 #252424a9, 0 2px #252424a9,
      0 -2px #252424a9, 1px 1px #252424a9, -1px -1px #252424a9,
      1px -1px #252424a9, -1px 1px #252424a9;

    .live-info {
      display: flex;
      gap: 5px;
    }

    img {
      width: 30px;
      height: 21px;
    }

    .turn-image {
      width: 30px;
      height: 21px;
      background-size: cover;
    }
  }

  .mana {
    border: 3px solid #006037;
    width: 195px;
    font-size: 10px;
    line-height: 1.8;
    border-radius: 6px;
    background-color: #d74564;
  }
`;

const BirdCard = (birdInfo) => {
  const { birdData } = birdInfo;
  const [birdSprite, setBirdSprite] = useState();

  useEffect(() => {
    let url;
    switch (birdData.type) {
      case "free":
        url = "/imgs/birds/defaultBird.png";
        break;
      case "standard":
        url = "/imgs/birds/standandBird.png";
        break;
      case "luxury":
        url = "/imgs/birds/luxuryBird.png";
        break;
      case "premium":
        url = "/imgs/birds/premiumBird.png";
        break;
      default:
        url = "/imgs/birds/defaultBird.png";
        break;
    }

    setBirdSprite(url);
  }, [birdData]);

  return (
    <BirdCardWrapper>
      <div className="sprite">
        <div
          className="animatedDiv"
          style={{ backgroundImage: `url(${birdSprite})` }}
        ></div>
      </div>
      <div className="birdName">{birdData.type}</div>
      <div className="birdData">
        <div className="live">
          <div className="live-info">
            <img src="/imgs/heart-icon.svg" alt="" />
            {birdData.heart}
          </div>

          <div className="live-info">
            <div
              style={{ backgroundImage: `url(${birdSprite})` }}
              className="turn-image"
            ></div>
            {birdData.remain_turn || '0'}
          </div>
        </div>

        <div className="mana">{birdData.mana}</div>
      </div>
    </BirdCardWrapper>
  );
};

export default BirdCard;
