import React, { useState } from "react";
import {
  useIsLoadingMarketNFTs,
  useMarketPlaceBirds,
  useWalletAddress,
} from "state/user/hooks";
import { BuyCard, ListCards } from "./ListCards";
import { Box, Flex, Text } from "@pancakeswap/uikit";
import styled from "styled-components";
import { useMemo } from "react";

const Select = styled.select`
  border: 0;
  background: #ffffff;
  padding: 10px;
  border-radius: 8px;
  color: #006037;
  font-family: "RetroGaming", sans-serif;
  font-size: 12px;
  outline: 0;

  option {
    padding: 8px;
  }
`;

const FILTER = {
  ALL: "all",
  MARKET: "market",
  MYBIRD: "my-bird",
};

const BirdList = () => {
  const account = useWalletAddress();
  const birdData = useMarketPlaceBirds();
  const [birdFilter, setBirdFilter] = useState(FILTER.ALL);
  const isLoading = useIsLoadingMarketNFTs();

  const handleChange = (event) => {
    setBirdFilter(event.target.value);
  };

  const filterList = useMemo(() => {
    if (birdFilter === FILTER.ALL) return birdData;
    if (birdFilter === FILTER.MARKET) {
      return birdData.filter(
        (bird) => bird.owner?.toLowerCase() !== account?.toLowerCase()
      );
    }
    return birdData.filter(
      (bird) => bird.owner?.toLowerCase() === account?.toLowerCase()
    );
  }, [account, birdData, birdFilter]);

  return (
    <>
      <Box paddingBottom={"16px"}>
        <Flex
          justifyContent={"flex-end"}
          style={{ gap: "4px" }}
          marginRight={["0px", "0px", "20px"]}
        >
          <Flex justifyContent={"center"} alignItems="center">
            <Text
              marginRight={"8px"}
              color="white"
              fontSize="12px"
              textAlign={"center"}
            >
              Filter by
            </Text>
          </Flex>
          <Select
            className="select-time"
            value={birdFilter}
            onChange={handleChange}
          >
            <option value="all">All</option>
            <option value="market">Market</option>
            <option value="my-bird">My bird</option>
          </Select>
        </Flex>
      </Box>
      <Box>
        <ListCards
          items={filterList}
          renderItem={(item) => <BuyCard birdData={item} />}
          isLoading={isLoading}
        />
      </Box>
    </>
  );
};

export default BirdList;
