import { CHAIN_ID } from "../utils/web3React";
import { parseUnits } from "@ethersproject/units";

const GAS_PRICE = {
  default: "5",
  fast: "6",
  instant: "7",
  testnet: "10",
};

const GAS_PRICE_GWEI = {
  default: parseUnits(GAS_PRICE.default, "gwei").toString(),
  fast: parseUnits(GAS_PRICE.fast, "gwei").toString(),
  instant: parseUnits(GAS_PRICE.instant, "gwei").toString(),
  testnet: parseUnits(GAS_PRICE.testnet, "gwei").toString(),
};

/**
 * Function to return gasPrice outwith a react component
 */
const getGasPrice = () => {
  const chainId = CHAIN_ID;
  const userGas = GAS_PRICE_GWEI.default;
  return chainId === "56" ? userGas : GAS_PRICE_GWEI.testnet;
};

export default getGasPrice;
