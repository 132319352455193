import { useState } from "react";
import { useCallback, useMemo } from "react";
import { useTokenContract } from "./useContract";
import useTokenAllowance from "./useTokenAllowance";
import { MaxUint256 } from "@ethersproject/constants";
import useToast from "hooks/useToast";

export const ApprovalState = {
  UNKNOWN: "UNKNOWN",
  NOT_APPROVED: "NOT_APPROVED",
  PENDING: "PENDING",
  APPROVED: "APPROVED",
};

const useApproveCallback = (amountToApprove, tokenAddress, spender) => {
  const [isLoading, setIsLoading] = useState(false);
  const { allowance: currentAllowance, mutate: refetchAllowance } =
    useTokenAllowance(tokenAddress, spender);
  const { toastSuccess, toastError } = useToast();

  const approvalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN;
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN;
    if (isLoading) return ApprovalState.PENDING;
    if (currentAllowance.lt(amountToApprove)) return ApprovalState.NOT_APPROVED;

    return ApprovalState.APPROVED;
  }, [amountToApprove, currentAllowance, isLoading, spender]);

  const tokenContract = useTokenContract(tokenAddress, true);

  const approve = useCallback(async () => {
    try {
      setIsLoading(true);
      const txn = await tokenContract.approve(spender, MaxUint256);
      const txnReceipt = await txn.wait();
      if (txnReceipt.status) {
        toastSuccess("Success", `Approve success!`);
        await refetchAllowance();
      } else {
        toastError("Failed", "Something wrong");
      }
    } catch (error) {
      toastError("Failed", "Something wrong");
    } finally {
      setIsLoading(false);
    }
  }, [tokenContract, spender, toastSuccess, refetchAllowance, toastError]);

  return [approvalState, approve];
};

export default useApproveCallback;
