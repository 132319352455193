import { crashAudio, getRewardAudio } from "components/audio/soundManager";
import socket from "utils/socket";
import {
  BIRDSIZE,
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  FLOOROFFSET,
  PipeSpeed,
  PIPE_WIDTH,
} from "../../data/constants";
import { getMap } from "../../endpoint";
export default class Pipe {
  constructor(p5, spriteImage) {
    this.p5 = p5;
    this.pipesPosition = [];
    this.image = spriteImage;
    this.pipeProgress = 0;
    this.map = [];
    this.countPixel = 0;
  }

  getMapPrepare = async ({ birdType, birdId, nftId }) => {
    const response = await getMap({ birdType, birdId, nftId });
    this.map = response.data;
    socket.emit("loadMap", response.data);
    return response.data;
  };

  countX = (frame) => {
    this.countPixel = frame * PipeSpeed;
  };

  generatePipeHeightAndGap() {
    const { below_pipe_height, pipe_gap, pipe_type, reward, pixel } =
      this.map[this.pipeProgress];
    this.pipeProgress++;
    return {
      height: below_pipe_height,
      gap: pipe_gap,
      pipe_type,
      reward,
      pixel,
    };
  }

  generateNew() {
    const { height, gap, pipe_type, reward, pixel } =
      this.generatePipeHeightAndGap();
    const offset = 0;
    this.pipesPosition.push({
      offset,
      height,
      gap,
      pipe_type,
      reward,
      pixel,
    });
    this.draw({ offset: 0, height, gap, pipe_type, reward });
  }

  generateFirst() {
    const { height, gap, pipe_type, reward, pixel } =
      this.generatePipeHeightAndGap();
    const offset = -100;
    this.pipesPosition.push({
      offset,
      height,
      gap,
      pipe_type,
      reward,
      pixel,
    });
    this.draw({
      offset,
      height,
      gap,
      pipe_type,
      reward,
    });
  }

  draw() {
    this.pipesPosition.forEach((pipe) => {
      let TopPipePosition;
      let BottomPipePosition;
      switch (pipe.pipe_type) {
        case "green":
          TopPipePosition = PIPE_WIDTH;
          BottomPipePosition = 0;
          break;
        case "yellow":
          TopPipePosition = 180;
          BottomPipePosition = 120;
          break;
        case "purple":
          TopPipePosition = 300;
          BottomPipePosition = 240;
          break;
        default:
          TopPipePosition = PIPE_WIDTH;
          BottomPipePosition = 0;
          break;
      }

      this.p5.image(
        this.image,
        CANVAS_WIDTH - pipe.offset,
        0,
        PIPE_WIDTH,
        pipe.height,
        TopPipePosition,
        500 - pipe.height,
        PIPE_WIDTH,
        pipe.height
      );
      this.p5.image(
        this.image,
        CANVAS_WIDTH - pipe.offset,
        pipe.height + pipe.gap,
        PIPE_WIDTH,
        CANVAS_HEIGHT - pipe.height - pipe.gap - FLOOROFFSET,
        BottomPipePosition,
        0,
        PIPE_WIDTH,
        CANVAS_HEIGHT - pipe.height - pipe.gap - FLOOROFFSET
      );
    });
  }

  move() {
    this.pipesPosition.forEach((pipe) => {
      pipe.offset = pipe.offset + PipeSpeed;
    });

    if (this.pipesPosition && this.pipesPosition.length > 0) {
      if (this.pipesPosition[0].offset > CANVAS_WIDTH + PIPE_WIDTH)
        this.pipesPosition.shift();

      if (this.pipesPosition[this.pipesPosition.length - 1]) {
        const pipeVerticalGap =
          this.map[this.pipeProgress].pixel -
          this.pipesPosition[this.pipesPosition.length - 1].pixel;
        if (
          this.pipesPosition[this.pipesPosition.length - 1].offset >
          pipeVerticalGap
        ) {
          this.generateNew();
        }
      }
    }
  }

  getScore(bird) {
    const birdMiddleX = bird.birdPosition.x + BIRDSIZE.Width / 2;
    let getScore = false;
    this.pipesPosition.forEach((pipe) => {
      const pipeMiddleX = CANVAS_WIDTH - pipe.offset + BIRDSIZE.Width;
      if (birdMiddleX > pipeMiddleX - 2 && birdMiddleX < pipeMiddleX + 2) {
        getScore = true;
        return getScore;
      }
    });
    return getScore;
  }

  getReward(bird) {
    const birdMiddleX = bird.birdPosition.x + BIRDSIZE.Width / 2;
    let reward = 0;
    this.pipesPosition.forEach((pipe) => {
      const pipeMiddleX = CANVAS_WIDTH - pipe.offset + BIRDSIZE.Width;
      if (birdMiddleX > pipeMiddleX - 2 && birdMiddleX < pipeMiddleX + 2) {
        reward = pipe.reward;
        if (reward > 0) {
          getRewardAudio();
        }
        return reward;
      }
    });
    return reward;
  }

  checkCrash(bird) {
    const birdStartX = bird.birdPosition.x;
    const birdEndX = bird.birdPosition.x + BIRDSIZE.Width;
    const birdStartY = bird.birdPosition.y;
    const birdEndY = bird.birdPosition.y + BIRDSIZE.Height;
    let crash = false;
    this.pipesPosition.forEach((pipe) => {
      const pipeStartX = CANVAS_WIDTH - pipe.offset;
      const pipeEndX = CANVAS_WIDTH - pipe.offset + PIPE_WIDTH;

      const topPipeStartY = 0;
      const topPipeEndY = pipe.height;

      const bottomPipeStartY = pipe.height + pipe.gap;
      const bottomPipeEndY = CANVAS_HEIGHT;

      if (
        ((birdStartX >= pipeStartX && birdStartX < pipeEndX) ||
          (birdEndX >= pipeStartX && birdEndX < pipeEndX)) &&
        ((birdStartY >= topPipeStartY && birdStartY < topPipeEndY) ||
          (birdEndY >= bottomPipeStartY && birdEndY < bottomPipeEndY))
      ) {
        crash = true;
        crashAudio();
        bird.reduceBirdLive();
      } else if (
        ((birdStartX >= pipeStartX && birdStartX < pipeEndX) ||
          (birdEndX >= pipeStartX && birdEndX < pipeEndX)) &&
        birdStartY < 0
      ) {
        crash = true;
        crashAudio();
        bird.reduceBirdLive();
      }
    });
    return crash;
  }
}
