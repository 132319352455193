import useToast from "hooks/useToast";
import { useDispatch } from "react-redux";
import { fetchUserDataAsync, fetchUserRewardAsync } from "state";
import buyPackageFn from "state/package/buyPackage";

const { useCallback } = require("react");

const useBuyPackage = () => {
  const { toastSuccess, toastError } = useToast();
  const dispatch = useDispatch();

  const buyPackage = useCallback(
    async (NFTId, pkg) => {
      try {
        await buyPackageFn(NFTId, pkg.id);
        dispatch(fetchUserDataAsync());
        dispatch(fetchUserRewardAsync());
        toastSuccess(
          "Success",
          `Buy ${pkg.turnNumber} turns for bird ${NFTId}`
        );
      } catch (error) {
        toastError("Failed", `${error?.message}`);
      }
    },
    [toastError, toastSuccess, dispatch]
  );

  return { onBuyPackage: buyPackage };
};

export default useBuyPackage;
