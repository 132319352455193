import React, { createContext, useState } from "react";

const AppContext = createContext();
export default AppContext;

export const AppContextProvider = (props) => {
  const [bird, setBird] = useState();
  const [gameConstants, setGameConstants] = useState();
  const [currentBird, setCurrentBird] = useState(["free"]);
  const [isLogin, setIsLogin] = useState(false);
  const [isPlayBackgroundSound, setIsPlayBackgroundSound] = useState(false);
  const contextValues = {
    gameConstants,
    setGameConstants,
    bird,
    setBird,
    currentBird,
    setCurrentBird,
    isLogin,
    setIsLogin,
    isPlayBackgroundSound,
    setIsPlayBackgroundSound,
  };

  return (
    <AppContext.Provider value={contextValues}>
      {props.children}
    </AppContext.Provider>
  );
};
