import styled from "styled-components";
import { Button } from "@pancakeswap/uikit";

const CustomButton = styled(Button)`
  margin: 5px;
  background: #006037;
  border-radius: 6px;
  color: #fff;
  text-decoration: none;
  outline: 2px;
`;

export default CustomButton;
