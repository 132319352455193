import useActiveWeb3React from "hooks/useActiveWeb3React";
import useAuth from "hooks/useAuth";
import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { loginFacebook, loginWithGoogle } from "../endpoint";
import { ConnectorNames } from "@pancakeswap/uikit";
import { useDispatch } from "react-redux";
import { setWalletAddress } from "state";

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;

  .logo {
    max-width: 220px;
    max-height: 120px;
    margin: 0 auto;
  }

  .title {
    font-size: 32px;
    line-height: 1.2;
    margin: 20px 0;
    text-shadow: 2px 0 #252424a9, -2px 0 #252424a9, 0 2px #252424a9,
      0 -2px #252424a9, 1px 1px #252424a9, -1px -1px #252424a9,
      1px -1px #252424a9, -1px 1px #252424a9;
  }

  .login-text {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    text-shadow: 2px 0 #252424a9, -2px 0 #252424a9, 0 2px #252424a9,
      0 -2px #252424a9, 1px 1px #252424a9, -1px -1px #252424a9,
      1px -1px #252424a9, -1px 1px #252424a9;
  }

  .button-group {
    margin-top: 30px;
    display: flex;
    gap: 10px;
  }

  @media screen and (max-width: 450px) {
    .button-group {
      display: flex;
      flex-direction: column;
    }
  }
`;

const LoginButton = styled.button`
  width: 200px;
  height: 48px;
  background: #ffffff;
  border-radius: 12px;
  font-family: "RetroGaming";
  color: #1e1c1c;
  text-transform: uppercase;
  font-size: 18px;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: ease-in-out 0.2s;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:hover {
    color: #006037;
    box-shadow: 4px 4px 0px #006037;
    border: 1px solid #006037;
  }
`;
const Login = () => {
  let navigate = useNavigate();
  const { activeMetamask, signSignature } = useAuth();
  const { active } = useActiveWeb3React();
  const dispatch = useDispatch();

  const responseGoogle = async (response) => {
    if (response.tokenId) {
      const googleInfo = {
        access_token: response.tokenId,
        user_id: response.googleId,
      };
      localStorage.setItem("gInfo", JSON.stringify(googleInfo));
      const responseData = await loginWithGoogle(googleInfo);
      if (responseData.refresh_token) {
        dispatch(setWalletAddress(responseData.address));
        localStorage.setItem("token", responseData.refresh_token);
        localStorage.setItem("address", responseData.address);
        navigate("/menu");
      }
    }
  };

  const responseFaceBook = async (response) => {
    if (response) {
      const fbInfo = {
        access_token: response.accessToken,
        user_id: response.userID,
      };
      localStorage.setItem("fbInfo", JSON.stringify(fbInfo));
      const responseData = await loginFacebook(fbInfo);
      if (responseData.refresh_token) {
        dispatch(setWalletAddress(responseData.address));
        localStorage.setItem("token", responseData.refresh_token);
        localStorage.setItem("address", responseData.address);
        navigate("/menu");
      }
    }
  };

  return (
    <LoginWrapper>
      <div className="contain">
        <img className="logo" src="/imgs/Savvygame.svg" alt="" />
        <h4 className="title">Hi! I’m Savvy bird</h4>
        <span className="login-text">Login with</span>
      </div>

      <div className="button-group">
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          render={(renderProps) => (
            <LoginButton
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <img src="/svgs/google.svg" alt="" />
              Google
            </LoginButton>
          )}
          buttonText="Login"
          cookiePolicy={"single_host_origin"}
        />
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          callback={responseFaceBook}
          render={(renderProps) => (
            <LoginButton
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <img src="/svgs/facebook.svg" alt="" /> Facebook
            </LoginButton>
          )}
        />

        {!active ? (
          <LoginButton
            onClick={() => {
              activeMetamask(ConnectorNames.Injected);
            }}
          >
            wallet
          </LoginButton>
        ) : (
          <LoginButton onClick={() => signSignature(true, false)}>
            sign
          </LoginButton>
        )}
      </div>
    </LoginWrapper>
  );
};

export default Login;
