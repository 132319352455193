import { ChevronLeft } from "@styled-icons/entypo/ChevronLeft";
import { ChevronRight } from "@styled-icons/entypo/ChevronRight";
import { LogOut } from "@styled-icons/entypo/LogOut";
import { Speaker1 } from "@styled-icons/fluentui-system-filled/Speaker1";
import { SpeakerMute } from "@styled-icons/fluentui-system-filled/SpeakerMute";
import { useWeb3React } from "@web3-react/core";
import useToast from "hooks/useToast";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clearUserData } from "state";
import styled from "styled-components";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { handleLogOut } from "utils/auth";
import AppContext from "../contexts/AppContext";
import { getBirds } from "../endpoint";
import BirdCard from "./BirdCard";

const MenuWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;
  flex-direction: column;
  gap: 16px;

  .copy-right {
    font-size: 10px;
    position: absolute;
    bottom: 5px;
  }

  .term-of-use {
    position: absolute;
    bottom: 5px;
    right: 5px;

    a {
      font-size: 10px;
      color: #fff;
    }
  }

  a {
    text-decoration: none;
  }

  .speaker {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9999999;
    display: flex;
    gap: 10px;

    button {
      border: 3px solid #fff;
      border-radius: 10px;
      width: 40px;
      height: 40px;
      outline: 0;
      background: none;
      cursor: pointer;
    }
  }
`;

const MenuButton = styled.div`
  width: 220px;
  height: 48px;
  background: #e86101;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  line-height: 24px;
  border: 4px solid #533000;
  border-bottom: 10px solid #533000;
  position: relative;

  text-shadow: 2px 0 #252424a9, -2px 0 #252424a9, 0 2px #252424a9,
    0 -2px #252424a9, 1px 1px #252424a9, -1px -1px #252424a9, 1px -1px #252424a9,
    -1px 1px #252424a9;

  &::before {
    content: " ";
    display: block;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 5px solid #ffffff;
  }
`;

const SelectBirdSection = styled.div`
  display: flex;
  position: relative;
  width: 300px;

  .navigation-btn {
    width: 30px;
    height: 30px;
    top: calc(50% - 30px);
    position: absolute;
    z-index: 9999999;
    cursor: pointer;
  }

  .left {
    left: 0;
  }

  .right {
    right: 0;
  }
`;

const Menu = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    currentBird,
    setCurrentBird,
    isPlayBackgroundSound,
    setIsPlayBackgroundSound,
  } = useContext(AppContext);
  const [activeSlide, setActiveSlide] = useState(0);
  const [bird, setBird] = useState();
  const { active, deactivate } = useWeb3React();

  useEffect(() => {
    (async () => {
      const response = await getBirds();
      setBird(response.data);
      setCurrentBird(response.data[0]);
    })();
  }, []);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const handleSetCurrentBird = (activeIndex) => {
    if (bird) {
      const birdData = bird[activeIndex];
      setCurrentBird(birdData);
      setActiveSlide(activeIndex);
    }
  };

  const logOut = () => {
    deactivate();
    dispatch(clearUserData());
    const logOut = handleLogOut();
    if (logOut) {
      navigate("/");
    }
  };
  const togglePlayAudio = () => {
    setIsPlayBackgroundSound(!isPlayBackgroundSound);
  };

  const { toastError } = useToast();

  const playGame = () => {
    if (currentBird && currentBird.remain_turn) {
      navigate("/game");
      return;
    }

    toastError("Not enough turns to play");
  };

  return (
    <MenuWrapper>
      <div className="speaker">
        <button type="button" onClick={togglePlayAudio}>
          {isPlayBackgroundSound ? (
            <Speaker1 color="#fff" />
          ) : (
            <SpeakerMute color="#fff" />
          )}
        </button>
        <button type="button" onClick={logOut}>
          <LogOut color="#fff" />
        </button>
      </div>
      <SelectBirdSection>
        <Swiper
          modules={[Navigation]}
          spaceBetween={50}
          slidesPerView={1}
          initialSlide={activeSlide}
          onSlideChange={(swiper) => handleSetCurrentBird(swiper.activeIndex)}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
        >
          {bird &&
            bird.length &&
            bird.map((data, index) => (
              <SwiperSlide key={index}>
                <BirdCard birdData={data} />
              </SwiperSlide>
            ))}
          <div className="navigation-btn left" ref={navigationPrevRef}>
            <ChevronLeft color="#fff" />
          </div>

          <div className="navigation-btn right" ref={navigationNextRef}>
            <ChevronRight color="#fff" />
          </div>
        </Swiper>
      </SelectBirdSection>
      <MenuButton onClick={playGame}>Play</MenuButton>
      <Link to={"/top-score"}>
        <MenuButton>Score & Reward</MenuButton>
      </Link>
      <Link to={"/market-place"}>
        <MenuButton>Maketplace</MenuButton>
      </Link>

      {/*<div className="copy-right">@Copyright Savvycom</div> */}

      <div className="term-of-use">
        <a
          href="https://savvycom.gitbook.io/savvycom.ai/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of use
        </a>
      </div>
    </MenuWrapper>
  );
};

export default Menu;
