import { loginWithGoogle as loginWithGoogleApi } from "../endpoint";
import { nodes } from "./getRpcUrl";

export const loginWithGoogle = async (googleInfo) => {
  const responseData = await loginWithGoogleApi(googleInfo);
  if (responseData.refresh_token) {
    localStorage.setItem("token", responseData.refresh_token);
  }
  return responseData;
};

export const handleLogOut = async () => {
  const keyToRemove = ["token", "fbInfo", "gInfo", "address"];
  keyToRemove.map((key) => localStorage.removeItem(key));
  return true;
};

const NETWORK_CONFIG = {
  97: {
    name: "BNB Smart Chain Testnet",
  },
  56: {
    name: "BNB Smart Chain Mainnet",
  },
  137: {
    name: "Polygon",
  },
  80001: {
    name: "Polygon Mumbai",
  },
};
export const setupNetwork = async (externalProvider) => {
  const provider = externalProvider || window.ethereum;
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);
  if (!NETWORK_CONFIG[chainId]) {
    console.error("Invalid chain id");
    return false;
  }
  if (provider) {
    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      });
      return true;
    } catch (switchError) {
      if (switchError?.code === 4902) {
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${chainId.toString(16)}`,
                chainName: NETWORK_CONFIG[chainId].name,
                nativeCurrency: {
                  name: "BNB",
                  symbol: "bnb",
                  decimals: 18,
                },
                rpcUrls: nodes,
                blockExplorerUrls: [`${NETWORK_CONFIG[chainId].scanURL}/`],
              },
            ],
          });
          return true;
        } catch (error) {
          console.error("Failed to setup the network in Metamask:", error);
          return false;
        }
      }
      return false;
    }
  } else {
    console.error(
      "Can't setup the BSC network on metamask because window.ethereum is undefined"
    );
    return false;
  }
};
