import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserDataAsync,
  fetchMarketPlaceAsync,
  fetchUserRewardAsync,
} from "./index";
import {
  userNFT,
  currentListingNFT,
  currentOrderNFT,
  marketBirds,
  currentRewards,
  isLoadingMyNFT,
  isLoadingMarketNFT,
  walletAddress,
} from "./selectors";
import useSWR from "swr";

const REFRESH_BLOCK_INTERVAL = 6000;

export const useFetchUserAsyncData = () => {
  const dispatch = useDispatch();

  useSWR(
    "fetchUserDataAsync",
    () => {
      dispatch(fetchUserDataAsync());
    },
    {
      refreshInterval: REFRESH_BLOCK_INTERVAL,
    }
  );
};
export const useFetchUserRewardsAsync = () => {
  const dispatch = useDispatch();

  useSWR(
    "useFetchUserRewardsAsync",
    () => {
      dispatch(fetchUserRewardAsync());
    },
    {
      refreshInterval: REFRESH_BLOCK_INTERVAL,
    }
  );
};

export const useUserNFT = () => {
  return useSelector(userNFT);
};

export const useCurrentListingNFT = () => {
  return useSelector(currentListingNFT);
};
export const useCurrentOrderNFT = () => {
  return useSelector(currentOrderNFT);
};

export const useFetchMarketPlace = () => {
  const dispatch = useDispatch();

  useSWR(
    "useFetchMarketPlace",
    () => {
      dispatch(fetchMarketPlaceAsync());
    },
    {
      refreshInterval: REFRESH_BLOCK_INTERVAL,
    }
  );
};

export const useMarketPlaceBirds = () => {
  return useSelector(marketBirds);
};

export const useCurrentRewards = () => useSelector(currentRewards);

export const useIsLoadingMyNFTs = () => useSelector(isLoadingMyNFT);

export const useIsLoadingMarketNFTs = () => useSelector(isLoadingMarketNFT);

// get address is set for current user in database
export const useWalletAddress = () => useSelector(walletAddress);
