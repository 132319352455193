const tokens = {
  SVC: {
    97: "0xFF3b8ae2FDfbA5daAdcee60FC3E346CAdBcdfb3c",
    56: "0x857b64d4A0ce517631210eaf9d512BC04485b439",
    137: "0x37856fc36f6981733b456F3C785407e28A933d3C",
    80001: "0xcf7a09171f2b1355cdd82bf03e2de3fc384a8e1e",
  },
  SVG: {
    97: "0x77C2dbB41822be178b506141a5DaF775766bC7a0",
    56: "0x37856fc36f6981733b456F3C785407e28A933d3C",
    137: "0x424a5afd431e1154320a221cfd991d3a6a71c117",
    80001: "0xa7B747eAE911eDA70BA31Da5607d82f3e5c34Ece",
  },
};

export default tokens;
