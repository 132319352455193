import { UnsupportedChainIdError } from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { setWalletAddress } from "endpoint";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSignatureLogin, postSignatureLogin } from "state/user/login";
import { setupNetwork } from "utils/auth";
import { localStorageData } from "utils/localStorageManager";
import { connectorsByName } from "../utils/web3React";
import useActiveWeb3React from "./useActiveWeb3React";
import { setWalletAddress as setWalletAddressAction } from "state";
import useToast from "hooks/useToast";
import { useWalletAddress } from "state/user/hooks";

const useAuth = () => {
  const navigate = useNavigate();
  const { toastError, toastInfo } = useToast();

  const { chainId, activate, deactivate, library, account, setError } =
    useActiveWeb3React();
  const signer = library.getSigner(account);
  const dispatch = useDispatch();
  const walletAddress = useWalletAddress();

  const activeMetamask = useCallback(
    async (connectorID) => {
      const connectorOrGetConnector = connectorsByName[connectorID];
      const connector =
        typeof connectorOrGetConnector !== "function"
          ? connectorsByName[connectorID]
          : await connectorOrGetConnector();

      if (typeof connector !== "function" && connector) {
        try {
          await activate(connector, async (error) => {
            console.log("error :>> ", error);
            if (error instanceof UnsupportedChainIdError) {
              setError(error);
              const provider = await connector.getProvider();
              const hasSetup = await setupNetwork(provider);
              if (hasSetup) {
                activate(connector);
              }
            }
            if (error instanceof NoEthereumProviderError) {
              window.open("https://metamask.io/download/");
            } else {
              window?.localStorage?.removeItem("connectorIdv2");
              if (error instanceof NoEthereumProviderError) {
                console.log(error);
              } else if (error instanceof UserRejectedRequestErrorInjected) {
                console.log(error);
              } else {
                console.log(error);
              }
            }
          });
        } catch (error) {
          console.log("error :>> ", error);
        }
      } else {
        window?.localStorage?.removeItem("connectorIdv2");
        console.log("error");
      }
    },
    [activate]
  );

  const signSignature = useCallback(
    async (isLogin = true, isLoginWalllet = true) => {
      try {
        const msgParams = await getSignatureLogin();
        !walletAddress &&
          isLoginWalllet &&
          toastInfo(
            "Note",
            "Each wallet address is just set only one for a Google account or Facebook account"
          );
        const signature = await signer.signMessage(msgParams);
        const gInfo = localStorage.getItem("gInfo");
        const fbInfo = localStorage.getItem("fbInfo");
        if (gInfo || fbInfo) {
          !walletAddress && (await setWalletAddress(signature));
        }
        const responseData = await postSignatureLogin(signature);
        dispatch(setWalletAddressAction(responseData.address));
        localStorage.setItem("token", responseData.refresh_token);
        localStorage.setItem("address", account);
        isLogin && navigate("/menu");
        return responseData;
      } catch (error) {
        if (error?.message) {
          toastError(
            "Auth",
            error.message + ". Please switch account on your metamask!!!"
          );
        } else {
          toastError("Auth", "Login fail");
        }
        console.error(error);
      }
    },
    [account, dispatch, navigate, signer, toastError, toastInfo, walletAddress]
  );

  const logout = useCallback(() => {
    deactivate();
  }, [deactivate, chainId]);

  return { activeMetamask, logout, signSignature };
};

export default useAuth;
