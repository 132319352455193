import { useContext, useEffect } from "react";
import Sketch from "react-p5";
import { useNavigate } from "react-router-dom";
import AppContext from "../contexts/AppContext";
import { CANVAS_HEIGHT, CANVAS_WIDTH, FRAMERATE } from "../data/constants";
import { updateScore } from "../endpoint";
import socket from "../utils/socket";
import Storage from "../utils/storage";
import Bird from "./game/bird";
import Floor from "./game/floor";
import GameArt from "./game/gameArt";
import { GameButton, GameButtonNew } from "./game/gameButton";
import Text from "./game/gameText";
import Pipe from "./game/pipe";
import Reward from "./game/reward";

export class GameData {
  preload(currentBird, p5) {
    let birdSpriteUrl;
    switch (currentBird.type) {
      case "free":
        birdSpriteUrl = "/imgs/birds/defaultBird.png";
        break;
      case "standard":
        birdSpriteUrl = "/imgs/birds/standandBird.png";
        break;
      case "luxury":
        birdSpriteUrl = "/imgs/birds/luxuryBird.png";
        break;
      case "premium":
        birdSpriteUrl = "/imgs/birds/premiumBird.png";
        break;
      default:
        birdSpriteUrl = "/imgs/birds/defaultBird.png";
        break;
    }
    this.p5js = p5;
    this.background = p5.loadImage("/imgs/background.png");
    this.spriteImage = p5.loadImage("/imgs/sprite.png");
    this.pipeImage = p5.loadImage("imgs/pipe.png");
    this.birdyFont = p5.loadFont("/fonts/RetroGaming.ttf");
    this.ground = p5.loadImage("/imgs/ground.png");
    this.birdSprite = p5.loadImage(birdSpriteUrl);
    this.goldImage = p5.loadImage("/imgs/SVG.svg");
    this.heartImage = p5.loadImage("/imgs/Heart.svg");
    this.manaImage = p5.loadImage("/imgs/mana.png");
    this.birdMana = currentBird?.mana;
    this.birdLive = currentBird?.heart;
  }

  async resetGame() {
    this.gameStart = false;
    this.gameOver = false;
    this.birdRevival = false;
    this.countFrame = 0;

    this.bird = new Bird(
      this.p5js,
      this.birdSprite,
      this.birdMana,
      this.birdLive,
      this.flappingSound,
      this.jumpAnimationFrame
    );
    this.pipe = new Pipe(this.p5js, this.pipeImage, this.map);
    this.floor = new Floor(this.p5js, this.ground);
    this.goldReward = new Reward(this.p5js, this.goldImage);
    this.gameText = new Text(this.p5js, this.birdyFont, this.windowSize);
    this.gameButton = new GameButton(
      this.p5js,
      this.gameText,
      this.spriteImage,
      this.windowSize
    );
    this.gameArt = new GameArt(
      this.p5js,
      this.gameText,
      this.goldImage,
      this.heartImage,
      this.manaImage,
      this.windowSize
    );
    this.storage = new Storage();
    await this.pipe.getMapPrepare({
      birdType: this.currentBird.type,
      birdId: this.currentBird.bird_id,
      nftId: this.currentBird?.nft_id,
    });
    this.pipe.generateFirst();
    this.score = 0;
    this.reward = 0;
    this.pipe.countPixel = 0;
    this.bird.draw();
    this.floor.draw();
    let dataFromStorage = this.storage.getStorageData();

    if (dataFromStorage === null) {
      this.bestScore = 0;
      this.totalReward = 0;
    } else {
      this.bestScore = dataFromStorage.bestScore;
      this.totalReward = dataFromStorage.totalReward;
    }
  }

  background;
  p5js;
  spriteImage;
  ground;
  pipeImage;
  birdyFont;
  gameStart;
  gameOver;
  bird;
  pipe;
  floor;
  gameButton;
  gameText;
  score;
  storage;
  bestScore;
  currentBird;
  birdMana;
  birdLive;
  reward = 0;
  totalReward;
  birdRevival;
  intervalTimeRevival;
  isReady = true;
  socketDie = false;
  socketGameOver = false;
  frameNumber = 0;
  birdSprite;
  goldReward;
  goldImage;
  goldAnimation = false;
  map;
  flappingSound;
  gameArt;
  heartImage;
  manaImage;
  isClickableCanvas = true;
  jumpAnimationFrame = 1;
  rewardAmount = 0;
  countFrame = 0;
  windowSize = { width: window.innerWidth, height: window.innerHeight };
  isMapLoaded = true;

  isMobile() {
    return this.windowSize.width < CANVAS_WIDTH / 2;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ gameData }) => {
  let navigate = useNavigate();
  const { currentBird } = useContext(AppContext);

  let revivalButton, finishButton, quitButton, playButton;

  useEffect(() => {
    if (currentBird.length < 0) {
      navigate("/menu");
      return;
    }
  }, [currentBird]);
  socket.on("die", (data) => {
    gameData.gameArt?.goldReward(data.newReward);
    gameData.gameArt?.manaBar(gameData.birdMana, data.newMana);
  });

  const resetGame = async () => {
    await gameData.resetGame();
  };

  const revivalBird = () => {
    onBirdJump(true);
    gameData.bird.resetBirdPosition(gameData.pipe.pipesPosition);
    gameData.isClickableCanvas = false;
    gameData.intervalTimeRevival = 3;
    gameData.isReady = false;
    gameData.socketDie = false;
    if (gameData.intervalTimeRevival >= 0 && gameData.birdRevival) {
      const timer = setInterval(() => {
        gameData.intervalTimeRevival--;
      }, 1000);

      setTimeout(() => {
        gameData.birdRevival = false;
        gameData.isClickableCanvas = true;
        clearInterval(timer);
        gameData.frameNumber = 0;
      }, 3000);
    }
  };

  const onBirdJump = (isJump) => {
    socket.emit("jump", gameData.frameNumber);
    gameData.bird.handleCountPixel(gameData.frameNumber);
    if (isJump) gameData.bird.jump();
    gameData.frameNumber = 0;
  };

  const drawRevivalButton = (p5) => {
    if (!revivalButton) {
      let xPosition = CANVAS_WIDTH / 2 - 85 - 150;
      if (gameData.isMobile()) {
        xPosition = CANVAS_WIDTH / 2 - 85;
      }
      let yPosition = CANVAS_HEIGHT / 2 + 50;
      revivalButton = new GameButtonNew(
        xPosition,
        yPosition,
        "Revival",
        gameData.spriteImage
      );
    }
    revivalButton.draw(p5);
  };

  const drawFinishButton = (p5) => {
    if (!finishButton) {
      let xPosition = CANVAS_WIDTH / 2 - 85 + 150;
      let yPosition = CANVAS_HEIGHT / 2 + 50;
      if (gameData.isMobile()) {
        xPosition = CANVAS_WIDTH / 2 - 85;
        yPosition = CANVAS_HEIGHT / 2 + 120;
      }

      finishButton = new GameButtonNew(
        xPosition,
        yPosition,
        "Finish",
        gameData.spriteImage
      );
    }
    finishButton.draw(p5);
  };

  const drawPlayButton = (p5) => {
    if (!playButton) {
      let xPosition = CANVAS_WIDTH / 2 - 85;
      let yPosition = CANVAS_HEIGHT / 2 + 150;

      playButton = new GameButtonNew(
        xPosition,
        yPosition,
        "Play",
        gameData.spriteImage
      );
    }
    playButton.draw(p5);
  };

  const drawQuitButton = (p5) => {
    if (!quitButton) {
      let xPosition = CANVAS_WIDTH / 2 - 85;
      let yPosition = CANVAS_HEIGHT / 2 + 180;

      quitButton = new GameButtonNew(
        xPosition,
        yPosition,
        "Quit",
        gameData.spriteImage
      );
    }
    quitButton.draw(p5);
  };

  const canvasClick = () => {
    if (!gameData.isClickableCanvas || !gameData.isMapLoaded) return;
    if (gameData.p5js.mouseButton === "left") {
      const mouseX = gameData.p5js.mouseX;
      const mouseY = gameData.p5js.mouseY;

      // when bird is dead
      if (gameData.birdRevival && !gameData.gameOver) {
        if (revivalButton && revivalButton.hitTest(mouseX, mouseY)) {
          revivalBird();
          return;
        }
        if (finishButton && finishButton.hitTest(mouseX, mouseY)) {
          gameOver();
          return;
        }
      }

      if (gameData.gameStart === false) {
        // quit button
        if (quitButton && quitButton.hitTest(mouseX, mouseY)) {
          navigate("/menu");
          return;
        } else {
          gameData.gameStart = true;
        }
      }

      if (!gameData.gameOver && gameData.gameStart && !gameData.birdRevival) {
        onBirdJump(true);
      }

      // play button
      if (playButton && playButton.hitTest(mouseX, mouseY)) {
        onBirdJump(false);
        resetGame();
      }
    }
  };

  const canvasTouch = () => {
    if (!gameData.isClickableCanvas || !gameData.isMapLoaded) return;
    const mouseX = gameData.p5js.mouseX;
    const mouseY = gameData.p5js.mouseY;
    // when bird is dead
    if (gameData.birdRevival && !gameData.gameOver) {
      if (revivalButton && revivalButton.hitTest(mouseX, mouseY)) {
        revivalBird();
        return;
      }
      if (finishButton && finishButton.hitTest(mouseX, mouseY)) {
        gameOver();
        return;
      }
    }

    if (gameData.gameStart === false) {
      // quit button
      if (quitButton && quitButton.hitTest(mouseX, mouseY)) {
        navigate("/menu");
        return;
      } else {
        gameData.gameStart = true;
      }
    }

    if (!gameData.gameOver && gameData.gameStart && !gameData.birdRevival) {
      onBirdJump(true);
    }

    // play button
    if (playButton && playButton.hitTest(mouseX, mouseY)) {
      onBirdJump(false);
      resetGame();
    }
  };

  const preload = (p5) => {
    gameData.preload(currentBird, p5);
  };

  const setup = (p5, canvasParentRef) => {
    const canvas = p5
      .createCanvas(CANVAS_WIDTH, CANVAS_HEIGHT)
      .parent(canvasParentRef);
    if (gameData.isMobile()) {
      canvas.touchStarted(canvasTouch);
    } else {
      canvas.mousePressed(canvasClick);
    }
    canvas.touchMoved((e) => {
      e.preventDefault();
    });
    p5.frameRate(FRAMERATE);
    resetGame();

    p5.keyPressed = (e) => {
      if (!gameData.isClickableCanvas) return;
      if (e.key === " ") {
        if (gameData.birdRevival && !gameData.gameOver) {
          revivalBird();
        }
        if (!gameData.gameOver) {
          onBirdJump(true);
        }
        if (gameData.gameStart === false) gameData.gameStart = true;
      }
      if (e.key === "r") {
        if (gameData.gameOver) {
          resetGame();
        }
      }
    };
  };

  const gameOver = async () => {
    if (!gameData.gameOver) {
      gameData.gameOver = true;
      if (!gameData.socketGameOver) {
        onBirdJump(false);
        gameData.socketGameOver = true;
      }
      gameData.bird.update();
      //call api update score
      await updateScore();
    }
  };

  const draw = (p5) => {
    p5.clear();
    p5.image(gameData.background, 0, 0, 960, 600);
    gameData.isMapLoaded = !(gameData.pipe.map.length === 0);

    if (gameData.gameStart && !gameData.birdRevival) {
      gameData.frameNumber++;
      gameData.countFrame++;
      gameData.pipe.countX(gameData.countFrame);
      gameData.pipe.move();
      gameData.pipe.draw();

      gameData.bird.update();
      gameData.bird.draw();

      gameData.floor.update();
      gameData.floor.draw();

      gameData.isReady = gameData.birdRevival =
        gameData.pipe.checkCrash(gameData.bird) || gameData.bird.isDead();

      if (gameData.bird.birdLive <= 0 || gameData.bird.birdMana <= 0) {
        gameOver();
      }

      if (gameData.pipe.getScore(gameData.bird)) gameData.score++;

      gameData.reward =
        gameData.reward + gameData.pipe.getReward(gameData.bird);
      if (gameData.pipe.getReward(gameData.bird) > 0) {
        gameData.rewardAmount = gameData.pipe.getReward(gameData.bird);
        gameData.goldAnimation = true;
      }

      if (gameData.goldAnimation) {
        gameData.goldReward.draw(gameData.gameText, gameData.rewardAmount);
        gameData.goldReward.update();
        setTimeout(() => {
          gameData.goldAnimation = false;
          gameData.goldReward.resetGoldAnimation();
        }, 2000);
      }
      if (gameData.bird.birdMana === 0) {
      }
    } else {
      gameData.pipe.draw();
      gameData.bird.draw();
      gameData.floor.draw();
    }

    if (gameData.gameStart === false) {
      gameData.gameText.startText();
      drawQuitButton(p5);
    }

    if (gameData.intervalTimeRevival > 0 && gameData.birdRevival) {
      gameData.gameText.delayTimeText(gameData.intervalTimeRevival);
    }
    if (gameData.birdRevival && !gameData.gameOver && gameData.isReady) {
      if (!gameData.socketDie) {
        onBirdJump(false);
        gameData.socketDie = true;
      }
      gameData.gameText.revivalTitleText();
      drawRevivalButton(p5);
      drawFinishButton(p5);
    }

    if (gameData.gameOver) {
      if (gameData.score > gameData.bestScore) {
        gameData.bestScore = gameData.score;
        gameData.storage.setStorageData({
          bestScore: gameData.score,
          totalReward: gameData.totalReward,
        });
      }
      if (gameData.reward > 0) {
        gameData.storage.setStorageData({
          bestScore: gameData.bestScore,
          totalReward: gameData.totalReward + gameData.reward,
        });
      }

      gameData.gameText.gameOverText(
        gameData.score,
        gameData.bestScore,
        gameData.reward,
        currentBird.type
      );

      drawPlayButton(p5);
    } else {
      gameData.gameText.scoreText(gameData.score);
    }
    gameData.gameArt.heartLive(gameData.bird.birdLive);
    gameData.gameArt.goldReward(gameData.reward, currentBird.type);
    gameData.gameArt.manaBar(gameData.birdMana, gameData.bird.birdMana);
  };

  return <Sketch preload={preload} setup={setup} draw={draw} />;
};
