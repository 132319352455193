import axios from "axios";
const ENDPOINT = `${process.env.REACT_APP_API_URL}/api`;

export const USER_ENDPOINT = `${ENDPOINT}/user`;
export const AUTH_ENDPOINT = `${ENDPOINT}/auth`;

export const axiosApiInstance = axios.create();
const generalAxios = axios.create();

// response interceptor
generalAxios.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response.data.error_code === '"invalid_token"') {
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

// request interceptor
axiosApiInstance.interceptors.request.use(
  function (config) {
    const bearToken = localStorage.getItem("token");
    config.headers = {
      Authorization: `Bearer ${bearToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
axiosApiInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    const toLogin = [401];
    const toMenu = [422];
    if (toLogin.includes(error.response.status)) {
      window.location.href = "/";
    } else if (toMenu.includes(error.response.status)) {
      window.location.href = "/menu";
    }

    return Promise.reject(error.response.data);
  }
);

export const loginWithGoogle = async (googleInfo) => {
  try {
    const result = await generalAxios.post(`${ENDPOINT}/auth/login/google`, {
      ...googleInfo,
    });
    return result.data;
  } catch (error) {
    console.error(error);
  }
};

export const getMap = async ({ birdType, birdId, nftId }) => {
  const clientId = localStorage.getItem("clientId");

  try {
    const result = await axiosApiInstance.post(
      `${USER_ENDPOINT}/generate-map`,
      { clientId, birdType, birdId, nftId }
    );
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const getBirds = async () => {
  try {
    const result = await axiosApiInstance.get(`${USER_ENDPOINT}/birds`);
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const updateScore = async () => {
  const clientId = localStorage.getItem("clientId");
  try {
    const result = await axiosApiInstance.post(
      `${USER_ENDPOINT}/update-score`,
      { clientId }
    );
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const getLeaderBoardData = async () => {
  try {
    const result = await axiosApiInstance.get(`${ENDPOINT}/get-leader-board`);
    return result.data;
  } catch (error) {
    console.error(error);
  }
};

export const getMyReward = async () => {
  try {
    const result = await axiosApiInstance.get(
      `${USER_ENDPOINT}/get-current-reward`
    );
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error(error?.message);
  }
};

export const getGameConfig = async () => {
  try {
    const result = await generalAxios.get(`${ENDPOINT}/get-config`);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCurrentReward = async () => {
  try {
    const result = await axiosApiInstance.get(
      `${USER_ENDPOINT}/get-current-reward`
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const claimReward = async () => {
  return axiosApiInstance.post(`${USER_ENDPOINT}/claim`);
};

export const loginFacebook = async (fbInfo) => {
  try {
    const result = await generalAxios.post(
      `${ENDPOINT}/auth/login/facebook`,
      fbInfo
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const setWalletAddress = async (signature) => {
  const result = await axiosApiInstance.post(`${USER_ENDPOINT}/set-address`, {
    signature,
  });
  return result.data;
};
