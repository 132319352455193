import getPackages from "./getPackages";
import _ from "lodash";
import buyPackage from "./buyPackage";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchPackageAsync = createAsyncThunk(
  "Package/fetchPackageAsync",
  async () => {
    const data = await getPackages();
    return data;
  }
);
export const buyPackageAsync = createAsyncThunk(
  "Package/buyPackageAsync",
  async (packageId) => {
    const id = await buyPackage(packageId);
    return id;
  }
);

const initialState = {
  list: {
    data: [],
    isLoading: false,
  },
  order: {
    data: [],
    isLoading: false,
  },
};

const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    firstAction: (state, { payload }) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackageAsync.pending, (state, { payload }) => {
        state.list.isLoading = true;
      })
      .addCase(fetchPackageAsync.fulfilled, (state, { payload }) => {
        state.list.isLoading = false;
        state.list.data = payload;
      })
      .addCase(fetchPackageAsync.rejected, (state, { payload }) => {
        state.list.isLoading = false;
        state.list.data = [];
      });
    builder
      .addCase(buyPackageAsync.pending, (state, { payload }) => {
        state.order.data.push(payload);
        state.order.isLoading = true;
      })
      .addCase(buyPackageAsync.fulfilled, (state, { payload }) => {
        _.remove(state.order.data, (el) => el === payload);
        state.order.isLoading = false;
      })
      .addCase(buyPackageAsync.rejected, (state, { payload }) => {
        _.remove(state.order.data, (el) => el === payload);
        state.order.isLoading = false;
      });
    builder.addDefaultCase((state, { payload }) => {});
  },
});

export default packageSlice.reducer;
