import { Modal } from "@pancakeswap/uikit";
import { useCallback, useMemo, useState } from "react";
import { ModalInput } from "common/components";
import { Button } from "common/components";
import { useTokenContract } from "hooks/useContract";
import tokens from "config/constants/tokens";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { parseEther } from "@ethersproject/units";
import useToast from "hooks/useToast";
import useTokenBalance from "hooks/useTokenBalance";
import { BigNumber } from "@ethersproject/bignumber";
import { getFullDisplayBalance } from "utils/formatBalance";

const ownerSVG = process.env.REACT_APP_OWNER_SVG;

const RechargeModal = ({ onDismiss }) => {
  const [val, setVal] = useState("");
  const { chainId } = useActiveWeb3React();
  const SVGContract = useTokenContract(tokens.SVG[chainId], true);
  const { toastSuccess, toastError } = useToast();
  const { balance: SVGBalance } = useTokenBalance(tokens.SVG[chainId]);

  const handleChange = useCallback(
    (e) => {
      if (e.currentTarget.validity.valid) {
        const value = Math.floor(e.currentTarget.value).toString();
        setVal(value.replace(/,/g, "."));
      }
    },
    [setVal]
  );

  const onConfirm = useCallback(
    async (val) => {
      try {
        const value = parseEther(val);
        if (BigNumber.from(SVGBalance.toFixed()).lt(value)) {
          throw Error("Not enough SVG");
        }
        const txn = await SVGContract.transfer(ownerSVG, value);
        const txnReceipt = await txn.wait();
        if (txnReceipt.status) {
          toastSuccess("Success", `Swap success ${val} SVG to Gold`);
        } else {
          toastError("Fail", `Swap fail ${val} SVG to Gold`);
        }
      } catch (error) {
        if (error?.message) {
          toastError("Fail", error?.message);
        } else {
          toastError("Fail", `Swap fail ${val} SVG to Gold`);
        }
      }
    },
    [SVGBalance, SVGContract, toastError, toastSuccess]
  );

  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(SVGBalance);
  }, [SVGBalance]);

  const handleSelectMax = useCallback(() => {
    setVal(Math.floor(fullBalance).toString());
  }, [fullBalance, setVal]);

  return (
    <Modal
      headerBackground="gradients.cardHeader"
      title={"Swap SVG to Gold"}
      onDismiss={onDismiss}
    >
      <ModalInput
        max={fullBalance}
        value={val}
        onChange={handleChange}
        inputTitle={"Amount"}
        onSelectMax={handleSelectMax}
        isShowMaxButton={true}
      />
      <Button
        onClick={async () => {
          onDismiss?.();
          await onConfirm(val);
        }}
      >
        Confirm
      </Button>
      <Button onClick={onDismiss}>Cancel</Button>
    </Modal>
  );
};

export default RechargeModal;
