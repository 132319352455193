import styled from "styled-components";
import { Text, Input, Flex } from "@pancakeswap/uikit";
import { Button } from "../Button";
// import { parseUnits } from "@ethersproject/units";
// import { formatBigNumber } from "utils/formatBalance";
// import { Button } from "common/components";

const StyledTokenInput = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.input};
  border-radius: 16px;
  box-shadow: ${({ theme, isWarning }) =>
    isWarning ? theme.colors.warning : theme.shadows.inset};
  color: ${({ theme }) => theme.colors.text};
  padding: 8px 16px 8px 0;
  width: 100%;
`;

const StyledInput = styled(Input)`
  box-shadow: none;
  width: 60px;
  margin: 0 8px;
  padding: 0 8px;
  border: none;

  ${({ theme }) => theme.mediaQueries.xs} {
    width: 80%;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 80%;
  }
`;

// const StyledErrorMessage = styled(Text)`
//   position: absolute;
//   bottom: -22px;
//   a {
//     display: inline;
//   }
// `;

const ModalInput = ({
  max,
  symbol,
  onChange,
  onSelectMax,
  value,
  addLiquidityUrl,
  inputTitle,
  decimals = 18,
  isShowMaxButton = false,
}) => {
  const isBalanceZero = max === "0" || !max;

  return (
    <div style={{ position: "relative" }}>
      <StyledTokenInput isWarning={isBalanceZero}>
        <Flex justifyContent="space-between" pl="16px">
          <Text fontSize="14px">{inputTitle}</Text>
        </Flex>
        <Flex alignItems="flex-end" justifyContent="space-around">
          <StyledInput
            pattern={`^[0-9]*[.,]?[0-9]{0,${decimals}}$`}
            inputMode="decimal"
            step="any"
            min="0"
            onChange={onChange}
            placeholder="0"
            value={value}
          />
          {isShowMaxButton && (
            <Button scale="sm" onClick={onSelectMax} mr="8px">
              Max
            </Button>
          )}
          <Text fontSize="16px">{symbol}</Text>
        </Flex>
      </StyledTokenInput>
    </div>
  );
};

export default ModalInput;
