import styled from "styled-components";

export const LeaderBoardWrapper = styled.div`
  max-width: 380px;
  width: 100%;
  height: 450px;
  background: rgba(172, 203, 103, 0.2);
  border: 3px solid #006037;
  backdrop-filter: blur(12px);
  border-radius: 20px;
  color: #fff;
  padding: 16px;
  position: relative;
  box-sizing: border-box;

  .back-icon {
    top: 23px;
    width: 30px;
    height: 30px;
    position: absolute;
    cursor: pointer;
  }

  .title {
    font-weight: 400;
    font-size: 28px;
    line-height: 1.5;
    align-items: center;
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
    text-shadow: 2px 0 #1d1d1d, -2px 0 #1d1d1d, 0 2px #1d1d1d, 0 -2px #1d1d1d,
      1px 1px #1d1d1d, -1px -1px #1d1d1d, 1px -1px #1d1d1d, -1px 1px #1d1d1d;
  }

  .navigate-button {
    display: flex;
    justify-content: space-between;

    .top-board {
      border-radius: 10px;
      display: flex;
      padding: 2px;
      background: rgba(0, 0, 0, 0.15);
    }

    button {
      outline: 0;
      border: 0;
      height: 36px;
      padding: 8px;
      border-radius: 8px;
      background: none;
      font-size: 12px;
      display: flex;
      color: #fff;
      font-family: "RetroGaming", sans-serif;
      cursor: pointer;
    }

    .active {
      background: linear-gradient(133.23deg, #fffaef 3.43%, #ffffff 96.74%);
      transition: all 0.2;
      color: #006037;
      font-weight: 600;
      align-items: center;
    }

    .select-time {
      border: 0;
      background: #ffffff;
      border-radius: 8px;
      color: #006037;
      font-family: "RetroGaming", sans-serif;
      font-size: 12px;
      outline: 0;

      option {
        padding: 8px;
      }
    }
  }

  .content {
    min-height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top-list {
      margin-top: 20px;
      max-height: 220px;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #54595e;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #005fdb92;
      }
    }

    .my-info {
      .title {
        align-items: center;
        letter-spacing: -0.05em;
        color: #ffffff;
        font-size: 18px;
        text-align: left;
        margin-bottom: 8px;
      }

      .my-point {
        display: flex;
        justify-content: space-between;

        .score {
          text-shadow: 2px 0 #252424a9, -2px 0 #252424a9, 0 2px #252424a9,
            0 -2px #252424a9, 1px 1px #252424a9, -1px -1px #252424a9,
            1px -1px #252424a9, -1px 1px #252424a9;
        }
      }

      .claim-button {
        font-size: 18px;
        padding: 10px 18px;
        background: #ffffff;
        border-radius: 12px;
        font-family: "RetroGaming", sans-serif;
        border: 0;
        outline: 0;
        box-sizing: border-box;
        transition: ease-in-out 0.2s;

        cursor: pointer;
        &:hover {
          color: #006037;
          box-shadow: 4px 4px 0px #006037;
        }
      }
    }
  }

  .sort-select {
    position: relative;

    .select-dropdown {
      position: absolute;
      transition: 0.2;
      z-index: 99;
      background-color: rgba(201, 187, 187, 0.15);
      border: 1px solid rgba(201, 187, 187, 0.5);
      list-style-type: none;
      padding: 8px;
      margin: 0;
      margin-top: 10px;

      li {
        padding: 6px 12px;
        font-size: 12px;

        &:hover {
          cursor: pointer;
          background-color: #006037;
        }
      }
    }
  }
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
