import { BigNumber as EthersBigNumber } from "@ethersproject/bignumber";
import { formatUnits } from "@ethersproject/units";
import BigNumber from "bignumber.js";
import memoize from "lodash/memoize";
import { BIG_TEN } from "./bigNumber";

/**
 * Method to format the display of wei given an EthersBigNumber object
 * Note: does NOT round
 */
export const formatBigNumber = (
  number,
  displayDecimals = 18,
  decimals = 18
) => {
  const remainder = number.mod(
    EthersBigNumber.from(10).pow(decimals - displayDecimals)
  );
  return formatUnits(number.sub(remainder), decimals);
};

export const getFullDecimalMultiplier = memoize((decimals) => {
  return BIG_TEN.pow(decimals);
});

export const getBalanceAmount = (amount, decimals = 18) => {
  return new BigNumber(amount).dividedBy(getFullDecimalMultiplier(decimals));
};

export const getFullDisplayBalance = (
  balance,
  decimals = 18,
  displayDecimals
) => {
  return getBalanceAmount(balance, decimals).toFixed(displayDecimals);
};
