import React, { useState } from "react";
import styled from "styled-components";

const UserItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 12px;

  .rank-icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .out-top {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #252424a9;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .user-info {
    p {
      text-shadow: 2px 0 #252424a9, -2px 0 #252424a9, 0 2px #252424a9,
        0 -2px #252424a9, 1px 1px #252424a9, -1px -1px #252424a9,
        1px -1px #252424a9, -1px 1px #252424a9;
    }
  }
`;

const TopUserItems = ({ data, type, index }) => {
  const accountEllipsis = data.address
    ? `${data.address.substring(0, 2)}...${data.address.substring(
        data.address.length - 4
      )}`
    : null;

  return (
    <UserItemWrapper>
      <div className="rank-icon">
        {index <= 2 ? (
          <img src={`/imgs/rank${++index}.svg`} alt="rank-icon" />
        ) : (
          <div className="out-top">{++index}</div>
        )}
      </div>
      <div className="user-info">
        <p>{type ? data.reward : data.score}</p>
        <p>{data.user}</p>
        <p>{data.address === "0x" ? data.name : accountEllipsis}</p>
      </div>
    </UserItemWrapper>
  );
};

export default TopUserItems;
