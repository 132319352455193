import { ConnectorNames, useModal } from "@pancakeswap/uikit";
import { ArrowLeft } from "@styled-icons/entypo/ArrowLeft";
import CustomModal from "components/Market/components/CustomModal";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import useAuth from "hooks/useAuth";
import useToast from "hooks/useToast";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCurrentRewards, useFetchUserRewardsAsync } from "state/user/hooks";
import { localStorageData } from "utils/localStorageManager";
import { claimReward, getLeaderBoardData, getMyReward } from "../../endpoint";
import { Items, LeaderBoardWrapper } from "./style";
import TopUserItems from "./TopUserItems";

const LeaderBoard = () => {
  useFetchUserRewardsAsync();
  const [boardType, setBoardType] = useState("high_score");
  const [timelineType, setTimelineType] = useState("weekly");
  const [leaderBoardData, setLeaderBoardData] = useState(null);
  const [data, setData] = useState([]);
  const [currentReward, setCurrentReward] = useState(0);
  const [isAddressExisted, setIsAddressExisted] = useState(false);
  const [isSigned, setIsSigned] = useState(false);

  const currentRewards = useCurrentRewards();

  const handleChange = (event) => {
    setTimelineType(event.target.value);
  };

  const navigate = useNavigate();
  const { toastSuccess, toastError } = useToast();

  const { activeMetamask, signSignature } = useAuth();
  const { account, active } = useActiveWeb3React();
  const connectWallet = () => {
    activeMetamask(ConnectorNames.Injected);
  };

  useEffect(() => {
    if (leaderBoardData) {
      const key = `${boardType}_${timelineType}`;
      setData(leaderBoardData[key]);
    }
  }, [boardType, timelineType, leaderBoardData]);

  useEffect(() => {
    if (localStorageData.walletAddress !== "null") {
      setIsAddressExisted(true);
    }
    const fetchData = async () => {
      const data = await getLeaderBoardData();
      setLeaderBoardData(data);
    };
    fetchData();
  }, [account, active, isSigned]);

  const fetchData = async () => {
    const data = await getMyReward();
    if (data) {
      setCurrentReward(data);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleClaimReward = async () => {
    try {
      const response = await claimReward();
      toastSuccess("Success", "Request sent.");
    } catch (error) {
      toastError("Error", error.message);
    }
  };

  const handleSignSignature = async () => {
    const response = await signSignature(false);
    if (response) {
      setIsSigned(true);
    }
  };

  const [onOpenClaimModal] = useModal(
    <CustomModal onConfirm={handleClaimReward} title="Claim reward" />
  );

  const onPressClaim = () => {
    if (isAddressExisted) {
      if (currentRewards < 20000) {
        toastError(
          "Notice",
          "Your reward is less than 20000, play more to claim"
        );
        return;
      }
      onOpenClaimModal();
      return;
    }
    connectWallet();
  };

  return (
    <LeaderBoardWrapper>
      <div className="back-icon" onClick={() => navigate("/menu")}>
        <ArrowLeft />
      </div>
      <h4 className="title">Leader Board</h4>
      <div className="navigate-button">
        <div className="top-board">
          <button
            className={boardType === "high_score" ? "active" : ""}
            onClick={() => setBoardType("high_score")}
          >
            Top score
          </button>
          <button
            className={boardType === "reward" ? "active" : ""}
            onClick={() => setBoardType("reward")}
          >
            Top reward
          </button>
        </div>

        <select
          className="select-time"
          value={timelineType}
          onChange={handleChange}
        >
          <option value="daily">Day</option>
          <option value="weekly">Week</option>
          <option value="monthly">Month</option>
          <option value="lifetime">Lifetime</option>
        </select>
      </div>

      <div className="content">
        <div className="top-list">
          <Items>
            {data.length &&
              data.map((item, index) => {
                // 0 - highs core 1 - reward
                return (
                  <TopUserItems
                    data={item}
                    index={index}
                    type={boardType == "high_score" ? 0 : 1}
                  />
                );
              })}
          </Items>
        </div>

        <div className="my-info">
          <div className="my-point">
            <div>
              <div className="score">
                Your Score: {currentReward?.highScore}
              </div>
              <div className="score">Your Reward: {currentRewards}</div>
            </div>
            {account && !isSigned ? (
              <button
                type="button"
                onClick={handleSignSignature}
                className="claim-button"
              >
                <span>Sign</span>
              </button>
            ) : (
              <button
                type="button"
                onClick={onPressClaim}
                className="claim-button"
              >
                <span>{isAddressExisted ? "Claim" : "Connect"}</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </LeaderBoardWrapper>
  );
};

export default LeaderBoard;
