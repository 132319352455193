export default class Floor {
  constructor(p5, ground) {
    this.p5 = p5;
    this.image = ground;
    this.startX = 0;
  }
  draw() {
    for (var i = 0; i <= 20; i++)
      this.p5.image(this.image, this.startX + i * 59.5, 540, 60, 0, 20, 20, 20);
  }
  update() {
    for (var i = 0; i <= 20; i++)
      this.p5.image(this.image, this.startX + i * 59.5, 540, 60, 0);
    this.startX -= 2;
    if (this.startX <= -59) this.startX = 0;
  }
}
