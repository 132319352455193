import { useCallback } from "react";
import { buyNFT as bugNFTUtil } from "../../../utils/calls/index";
import { useMarketContract } from "../../../hooks/useContract";
import useTxnDeadline from "hooks/useTxnDeadline";
import { useDispatch } from "react-redux";
import * as userAction from "state/user/index";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import useToast from "hooks/useToast";
import useTokenBalance from "hooks/useTokenBalance";
import tokens from "config/constants/tokens";
import { BigNumber } from "bignumber.js";

const useBuyNFT = (birdData) => {
  const marketPlace = useMarketContract();
  const deadline = useTxnDeadline();
  const dispatch = useDispatch();
  const { nftId, owner, name, price } = birdData;
  const { account, chainId } = useActiveWeb3React();
  const { toastSuccess, toastError } = useToast();
  const { balance: SVCBalance } = useTokenBalance(tokens.SVC[chainId]);

  const buyNFT = useCallback(async () => {
    if (SVCBalance.isLessThan(new BigNumber(price))) {
      toastError("Failed", "Not enought SVC");
      return;
    }

    try {
      dispatch(userAction.buyNFT.pending({ nftId }));
      await bugNFTUtil(marketPlace, account, owner, nftId, deadline);
      dispatch(userAction.buyNFT.fulfilled({ nftId }));
      toastSuccess("Success", `Buy ${name}`);
    } catch (error) {
      dispatch(userAction.buyNFT.rejected({ nftId }));
      toastError("Failed", "Something wrong");
    }
  }, [
    SVCBalance,
    account,
    deadline,
    dispatch,
    marketPlace,
    name,
    nftId,
    owner,
    price,
    toastError,
    toastSuccess,
  ]);

  return { onBuyNFT: buyNFT };
};

export default useBuyNFT;
