import {
  configureStore,
  getDefaultMiddleware,
  combineReducers,
} from "@reduxjs/toolkit";
import userReducer from "./user";
import packageReducer from "./package";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, userReducer);

const store = configureStore({
  reducer: combineReducers({
    user: persistedReducer,
    package: packageReducer,
  }),
  middleware: getDefaultMiddleware,
});

export default store;
export * from "./user";

export const persistor = persistStore(store);
