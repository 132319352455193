export const BirdType = {
  FREE: "free",
  STANDARD: "standard",
  LUXURY: "luxury",
  PREMIUM: "premium",
};

export * from "./contracts";

export const FAST_INTERVAL = 10000;
