import { Contract } from "@ethersproject/contracts";
import { simpleRpcProvider } from "../utils/providers";
import marketPlace from "../config/abi/Marketplace.json";
import { getMarketPlaceAddress } from "./addressHelpers";

export const getContract = (abi, address, signer = null) => {
  const signerOrProvider = signer ?? simpleRpcProvider;

  return new Contract(address, abi, signerOrProvider);
};

export const getMarketPlaceContract = (signer = null) => {
  return getContract(marketPlace, getMarketPlaceAddress(), signer);
};
