import { CANVAS_HEIGHT, CANVAS_WIDTH } from "../../data/constants";

export default class GameText {
  constructor(p5, font, windowSize) {
    this.p5 = p5;
    p5.textFont(font);
    this.p5.strokeWeight(5);
    this.p5.stroke(51);
    this.p5.fill("white");
    this.p5.textAlign(this.p5.CENTER);
    this.windowSize = windowSize;
  }

  scoreText(score) {
    this.p5.textSize(50);
    this.p5.text(score, 0, 100, CANVAS_WIDTH);
  }

  startText() {
    this.p5.textSize(30);
    this.p5.text("Click or", 0, CANVAS_HEIGHT / 2 + 60, CANVAS_WIDTH);
    this.p5.text("press Space", 0, CANVAS_HEIGHT / 2 + 95, CANVAS_WIDTH);
    this.p5.text("to Fly", 0, CANVAS_HEIGHT / 2 + 135, CANVAS_WIDTH);
  }

  gameOverText(score, bestScore, reward, birdType) {
    this.p5.textSize(40);
    this.p5.text("Game Over", 0, CANVAS_HEIGHT / 2 - 100, CANVAS_WIDTH);
    this.p5.text("Score: " + score, 0, CANVAS_HEIGHT / 2, CANVAS_WIDTH);
    this.p5.text("Best: " + bestScore, 0, CANVAS_HEIGHT / 2 + 50, CANVAS_WIDTH);
    if (birdType !== "free") {
      this.p5.text(
        "Reward: " + reward,
        0,
        CANVAS_HEIGHT / 2 + 100,
        CANVAS_WIDTH
      );
    }
  }

  resetText() {
    this.p5.textSize(25);
    return this.p5.text("Play", 0, CANVAS_HEIGHT / 2 + 185, CANVAS_WIDTH);
  }

  liveInfo(live, responsivePosition) {
    this.p5.textSize(15);
    return this.p5.text(`${live}`, 15 + responsivePosition, 75, 110);
  }

  manaInfo(total, current, responsivePosition) {
    this.p5.textSize(15);
    return this.p5.text(
      `${current}/${total}`,
      45 + responsivePosition,
      115,
      110
    );
  }

  rewardInfo(reward, responsivePosition, isFreeBird) {
    this.p5.textSize(15);
    return this.p5.text(
      `${reward}`,
      15 + responsivePosition,
      40,
      isFreeBird ? 180 : 110
    );
  }

  revivalTitleText() {
    let textSize = 25;
    if (this.windowSize.width < CANVAS_WIDTH) {
      textSize = 20;
    }
    this.p5.textSize(textSize);
    this.p5.text("Press Revival to continue ", 0, 250, CANVAS_WIDTH);
    this.p5.text("or Restart game", 0, 290, CANVAS_WIDTH);
  }

  backToHomeText() {
    this.p5.textSize(25);
    this.p5.text("Quit", 0, CANVAS_HEIGHT / 2 + 215, CANVAS_WIDTH);
  }

  revivalText(isMobile) {
    let xPosition = CANVAS_WIDTH / 2 + 170;
    if (isMobile) {
      xPosition = CANVAS_WIDTH;
    }
    this.p5.textSize(20);
    this.p5.text("Revival", 0, CANVAS_HEIGHT / 2 + 85, xPosition);
  }

  restartText(isMobile) {
    let xPosition = CANVAS_WIDTH + 300;
    let yPosition = CANVAS_HEIGHT / 2 + 85;
    if (isMobile) {
      xPosition = CANVAS_WIDTH;
      yPosition = CANVAS_WIDTH / 2 - 25;
    }
    this.p5.textSize(20);
    this.p5.text("Finish", 0, yPosition, xPosition);
  }

  delayTimeText(time) {
    this.p5.textSize(80);
    this.p5.text(`${time}`, 0, CANVAS_HEIGHT / 2, CANVAS_WIDTH);
  }

  rewardAmount(reward, offset, velocity) {
    this.p5.textSize(30);
    this.p5.text(`+${reward}`, 0, velocity, offset);
  }
}
